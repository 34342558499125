import * as THREE from 'three'
export default function genGalaxy () {
// return 

    const wait = setInterval( _=>{
        var img = this.plane?.material?.map?.image

        if ( !img ) return 
        clearInterval( wait )
       
        var canvas = document.createElement('canvas');
        canvas.width = 1024
        canvas.height = 1024

        var context = canvas.getContext('2d');
        context.drawImage(img, 0, 0);

        const data = context.getImageData( 0, 0, canvas.height, canvas.width ).data
	

		const pixelValues = []

        const pos = this.particles.geometry.attributes.position
        const col = this.particles.geometry.attributes.color 
        const posArray = pos.array 
        const colArray = col.array 

		for ( let i = 0; i < Math.floor(data.length ) ; i ++ ) {
			pixelValues.push( [ data[ i * 4 +  0 ], data[ i * 4 +  1 ], data[ i * 4 + 2], data[ i * 4 + 3 ] ]  )
            const x = i % 1024
            const y = Math.floor( i /1024 )

            posArray[ i * 3 + 0 ] = x - 1024 / 2
            posArray[ i * 3 + 1 ] = y - 1024 / 2
            posArray[ i * 3 + 2 ] = ( Math.random() - 0.5) * 100 

            colArray[ i * 3 + 0 ] = data[ i * 4 +  0 ] / 255
            colArray[ i * 3 + 1 ] = data[ i * 4 +  1 ] / 255
            colArray[ i * 3 + 2 ] = data[ i * 4 +  2 ] / 255 


            



		}	
        pos.needsUpdate = true
        col.needsUpdate = true 

        this.particles.scale.setScalar( 0.3 )

        // console.log( pixelValues  , )
    }, 500 )
    

    document.body.classList.add('entered')
    this.plane.visible = false 
    this.me.ctrl.setActive( true )
      
}