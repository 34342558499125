export default class Preloader {
    constructor() {

        this.onAssetLoaded = this.onAssetLoaded.bind(this)
        this.dom = document.querySelector("[data-app-preloader]")
        this.msg = document.querySelector("[data-app-preloader-message]")
        this.loadingCont = document.querySelector("#loadingCont")
        this.unsupportedDiv = document.querySelector("[data-app-unsupported]")





    }
    showUnsupportedMessage() {
        this.fadeOut()
        this.unsupportedDiv.style.display = "flex"
    }
    fadeOut() {
        document.body.classList.add('loaded')

return
        

        if ( !this.dom ) return 
        
        this.dom.style.opacity = 0
        this.loadingCont.style.pointerEvents = 'none'

        if (this.loadingCont) this.loadingCont.style.opacity = 0

        setTimeout(_ => {
            this.dom.parentElement.removeChild(this.dom)
        }, 1000)



    }
    onAssetLoaded(d) {

    }
}



