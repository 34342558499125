import * as THREE from 'three';
import { cloud, three, input, data, ui } from "xx-packages"
import * as xx from 'xx-packages'

import generateHubble from './methods/hubble';
import generatePlanets from './methods/planets';
import generatePoints from './methods/points';
import generateGalaxy from './methods/galaxy'
import generatePlanetLabels from './methods/planetLabels'
import initAudio from './methods/initAudio';
import initNavControls from './methods/initNavControls';

import { initSupernova, startSupernova, updateSupernova, resetSupernova } from './methods/initSupernova'

import createTimeline from './timeline';
import { updateTimeline, startNext, startPrevious } from '../updateTimeline';
import fullScreenBody from './fullScreenBody';

const { EasingValue } = data
const { cloudClient, typeManager } = cloud
const { inputManager } = input
const { VisComp, materialManager } = three



function intervalPerc(perc, start, end) {
    perc = THREE.Math.clamp(perc, 0, 1)
    if (perc < start) return 0
    if (perc > end) return 1
    return (perc - start) / (end - start)
}
function damp(x, y, lambda, dt) {

    return THREE.Math.lerp(x, y, 1 - Math.exp(- lambda * dt));

}

const v = new THREE.Vector3()

const v2 = new THREE.Vector3()


var frustum = new THREE.Frustum();
var cameraViewProjectionMatrix = new THREE.Matrix4();

class ServiceNow extends VisComp {
    constructor() {

        super();
        this.update = this.update.bind(this)
        this.initNavControls = initNavControls.bind(this)
        this.generateHubble = generateHubble.bind(this)
        this.generatePlanets = generatePlanets.bind(this)
        this.generatePoints = generatePoints.bind(this)
        this.generateGalaxy = generateGalaxy.bind(this)
        this.generatePlanetLabels = generatePlanetLabels.bind(this)
        this.initSupernova = initSupernova.bind(this)
        this.updateSupernova = updateSupernova.bind(this)
        this.startSupernova = startSupernova.bind(this)
        this.resetSupernova = resetSupernova.bind(this)

        this.initAudio = initAudio.bind(this)
        this.updateTimeline = updateTimeline.bind(this)
        this.startNext = startNext.bind(this)
        this.startPrevious = startPrevious.bind(this)

        this.createTimeline = createTimeline.bind(this)

        window.p = this
        window.xx = xx
        window.i = inputManager
        //console.log(inputManager)

        this.hubbleOffsetY = 0.15
        this.hubbleInstructionsOffsetTop = - 0.45

    }

    async start(doc = null, canvas) {
        super.start();

        this.visible = true 

        this.isFirefox = this.scene.app.device.isFirefox()


        let hasTouchScreen = false;
        if ("maxTouchPoints" in navigator) {
            hasTouchScreen = navigator.maxTouchPoints > 0;
        } else if ("msMaxTouchPoints" in navigator) {
            hasTouchScreen = navigator['msMaxTouchPoints'] > 0;
        } else {
            let mQ = window.matchMedia && matchMedia("(pointer:coarse)");
            if (mQ && mQ.media === "(pointer:coarse)") {
                hasTouchScreen = !!mQ.matches;
            } else if ('orientation' in window) {
                hasTouchScreen = true; // deprecated, but good fallback
            }
        }

        if ( window.innerWidth < 1025 && hasTouchScreen ){
            document.body.classList.add('Mobile')
        }
        window.addEventListener('resize', _=>{
            let hasTouchScreen = false;
            if ("maxTouchPoints" in navigator) {
                hasTouchScreen = navigator.maxTouchPoints > 0;
            } else if ("msMaxTouchPoints" in navigator) {
                hasTouchScreen = navigator['msMaxTouchPoints'] > 0;
            } else {
                let mQ = window.matchMedia && matchMedia("(pointer:coarse)");
                if (mQ && mQ.media === "(pointer:coarse)") {
                    hasTouchScreen = !!mQ.matches;
                } else if ('orientation' in window) {
                    hasTouchScreen = true; // deprecated, but good fallback
                }
            }
    
            if ( window.innerWidth < 1025 && hasTouchScreen ){
                document.body.classList.add('Mobile')
            } else {
                document.body.classList.remove('Mobile')
            }

        })


        fullScreenBody( this.onOrientationChangeCallback )
        this.listenToVisibility()

        this.planetStartTime = 2
        this.distances_closestLabel = 10
        this.distances_furthestLabel = 19

        // const cons = document.createElement('div')
        // document.body.appendChild( cons )
        // cons.style = `
        //     position: fixed;
        //     width: 300px;
        //     height: 100px;
        //     top: 0;
        //     left: 0;
        //     background: black;
        //     color: white 
        // `
        // this.cons = cons 
        // cons.id = 'cons'

        // cons.textContent = inputManager._touch._targetDiv.id 
        inputManager._touch.scroll = true

        const ambient = new THREE.AmbientLight(0xffffff, 0.3)
        const dir = new THREE.DirectionalLight(0xffffff, 1)
        dir.position.set(0.5, 0, 0.866);



        this.cont3D.add(dir)
        this.dirLight = dir

        this.dirLight.position.y = 100
        this.dirLight.position.z = 100

        this.children[2].particles.material.opacity = 0

        // this.cont

        this.cont3D.add(ambient)
        this.cont3D.add(this.me.camera)
        // this.renderer.renderer.setClearColor( 0xffffff)


       // console.log('Model Preview comp', this)

        this.easedScroll = 0


        this.doc = doc


        this.rotSpeed = 0.002

        await Promise.all([
            this.generateHubble(),
            this.generatePlanets()
        ])
        this.generatePoints()


        this.initSupernova()
        this.generateMat()
        this.generatePlanetLabels()


        const plane = new THREE.Mesh(
            new THREE.PlaneBufferGeometry(),
            // new THREE.SphereBufferGeometry(100, 23, 32),
            new THREE.MeshBasicMaterial()
        )

        this.cont3D.add(plane)
        this.texInd = -1

        this.plane = plane
        plane.position.set(0, 0, -40)
        plane.renderOrder = -1




        // this.texInd = 1
        this.nextSky()

        document.querySelector('header').addEventListener('click', _ => {
            if ( this.isLandscape ) return 
            this.started = true
            document.body.classList.add('entered')
            document.querySelectorAll('nav li').forEach(el => el.tabIndex = 0)
            // this.timelineInd = 1 
            // this.timeline[ 1 ].onStart()
        }, { once: true })
        this.initAudio()


        this.planetMixer.setTime( 5 )

        //  Precompile materials
        this.renderer.renderer.compile(this.renderer.scene, this.me.camera)
        this.cont3D.traverse(c => c.frustumCulled = false)
        this.me.camera.rotation.set(0, 0, 0)
        this.renderer.renderer.render(this.renderer.scene, this.me.camera)
        this.cont3D.traverse(c => c.frustumCulled = true)

        this.me.camera.position.set(0, 0, 25)
        this.me.camera.rotation.set(0.3, 0, 0)

        this.planetAction.reset()
        this.planetMixer.setTime( 0 )


        const influences = {
            start: 0,
            '0': 0,
            '1': 0,
            a: 0
        }

        this.influences = influences

        this.initNavControls()

        this.me.camera.position.z = 25
        this.me.ctrl.setActive(false)

        this.hubble = this.model.getObjectByName('Dummy001')
        this.hubbleScale = this.hubble.scale.x

        this.me.ctrl._targetObj = this.hubble


        this.hubbleIn = {
            on: false,
            progress: 0,
            duration: 2
        }
        this.hubbleOut = {
            on: false,
            progress: 0,
            duration: 2
        }
        this.planetsIn = {
            on: false,
            progress: 0,
            duration: 2
        }
        this.planetsOut = {
            on: false,
            progress: 0,
            duration: 2
        }

        this.interactionPromptAni = {
            on: false,
            progress: 0,
            duration: 0.5,
            from: 0,
            to: 1
        }

        this.idleRotSpeed = 0.1

        this.playedVos = {}
        const container = document.querySelector('#content')
        this.scrollHeight = container.scrollHeight
        this.currentScroll = container.scrollTop
        container.addEventListener('scroll', _ => {
            this.currentScroll = container.scrollTop
            // console.log( 'scroll')
            // if ( !this.entered ) {
            //     this.entered = true 
            //     document.body.classList.add('entered')
            // }
        })

        const header = document.querySelector('header')
        const sections = [...document.querySelectorAll('.SectionWrap')]

        this.scrollingSections = [
            {
                dom: header,
                height: header.getBoundingClientRect().height,
                offsetTop: header.offsetTop,
                viewed: false
            }
        ]
        for (let section of sections) {
            this.scrollingSections.push({
                dom: section,
                height: section.getBoundingClientRect().height,
                offsetTop: section.offsetTop + header.getBoundingClientRect().height,
                viewed: false
            })
        }
        for (let scrollSection of this.scrollingSections) {
            let options = {
                root: document.body,
                rootMargin: '0px',
                threshold: [0, 0.1, 0.5, 0.9, 1]
            }
            scrollSection.inViewport = false
            const callback = entries => {
                for (let entry of entries) {
                    if (entry.intersectionRatio < 0.01) scrollSection.inViewport = false
                    else {
                        scrollSection.inViewport = true

                    }
                    if (entry.intersectionRatio > 0.5) {
                        if (!scrollSection.viewed) {
                            scrollSection.viewed = true
                            scrollSection.dom.classList.add('viewed')
                        }
                        scrollSection.dom.classList.add('viewed')
                    }
                    if (entry.intersectionRatio < 0.1) {
                        scrollSection.dom.classList.remove('viewed')
                    }
                }
            }
            let observer = new IntersectionObserver(callback, options);
            //   observer.observe( scrollSection.dom  )
        }




        this.time = 0

        this.timelineInd = 0
        this.timeline = this.createTimeline(this)

        this.mouse = new THREE.Vector2()
        this.raycaster = new THREE.Raycaster()
        document.getElementById('content').addEventListener('touchstart', this.onTouchStart)
        document.getElementById('content').addEventListener('touchend', this.onTouchEnd)


    }
    onVoiceOverEnd = i => {
        this.playedVos = this.playedVos || {}
        this.playedVos[i] = true
        this.currentlyPlaying = null

        document.getElementById('continueScrolling').style.opacity = 1 

        const swipeToContinue = this.currentSection.querySelector('.SwipeToContinue')
        if ( swipeToContinue ){
            swipeToContinue.style.opacity = 1 
        }
    }
    goToSection(ind) {

    }
    resetPlanets = _ => {
        this.planets.visible = false
        this.planetMixer.setTime(0)
    }


    /**
     * 
     * Fade nav in  once we've scrolled past the header
     */
    initNavReveal = _ => {
        // let options = {
        //     root: document.body,
        //     rootMargin: '0px',
        //     threshold: [0,0.1, 0.5, 1]
        // }
        // const callback = entries => {
        //     for (let entry of entries) {
        //         this.entered = true 
        //         if ( entry.intersectionRatio < 0.2 ) document.body.classList.add('entered')
        //         // else document.body.classList.remove('entered')
        //     }
        // }
        // let observer = new IntersectionObserver(callback, options);
        // observer.observe( document.querySelector('header'))
    }
    initCtrlToggle = _ => {
        let options = {
            root: document.body,
            rootMargin: '0px',
            threshold: [0, 0.1, 0.5, 1]
        }
        const callback = entries => {
            for (let entry of entries) {

                if (entry.intersectionRatio < 0.2) this.me.ctrl.setActive(false)
                else this.me.ctrl.setActive(true)
            }
        }
        let observer = new IntersectionObserver(callback, options);
        observer.observe(document.querySelector('#section4'))
    }


    generateMat() {
        const mat = this.inputs.get('textures_sky');
        this.materials = [];
        for (let i = 0; i < mat.count; i++) {
            this.materials.push(
                materialManager.build({
                    base: this.scene.baseMat,
                    def: this.scene.defaultMat,
                    asset: mat.getNext()
                }))
            const doc = cloudClient.getDoc(mat.elems[i])
            this.materials[i] = {
                tex: this.materials[i].map,
                ratio: parseFloat(doc.m.fields.ratio)

            }


        };





    }
    nextSky = _ => {
        const { plane } = this
        this.texInd++
        this.texInd %= this.materials.length
        plane.material.map = this.materials[this.texInd].tex
        plane.scale.set(
            1.499267935578331 * 150,
            150,
            1
        )
        plane.material.side = THREE.DoubleSide
        plane.material.map.magFilter = THREE.LinearFilter;
        plane.material.map.minFilter = THREE.LinearFilter;
        // plane.material.map.anisotropy = this.renderer.renderer.getMaxAnisotropy();
        plane.material.map.encoding = THREE.sRGBEncoding
        plane.material.map.needsUpdate = true
        plane.material.transparent = true
        plane.material.opacity = 0.5


    }


    /**
     * negative when entering viewport from bottom
     *  positive when exiting the viewport from the top
     *  
     */
    getSectionScrollPercentage = section => {
        const { height, offsetTop } = section
        let { currentScroll } = this
        currentScroll = this.easedScroll

        const perc = (currentScroll - offsetTop) / height

        return perc
    }
    isSectionInViewport = section => {
        const { height, offsetTop } = section
        const { currentScroll } = this

        if (offsetTop < currentScroll) return true

    }



    revealSection(i) {
        const section = document.querySelector('#section' + i)
        if (section === this.currentSection) return
        section.classList.add('viewed')
        this.currentNavButton.classList.remove('current')
        this.currentNavButton = this.navButtons[i - 1]
        this.currentNavButton.classList.add('current')

        if (this.currentSection) this.currentSection.classList.remove('viewed')
        this.currentSection = section
    }
    hideFirst = _=>{
        const section = document.querySelector('#section' + 1)
        
        section.classList.remove('viewed')
 
        this.currentSection = null
    }
    playVO(ind) {
        if (this.currentlyPlaying !== ind) {
        //    console.log('Play VO: ', ind)
            if (this.voiceOvers[this.currentlyPlaying]) this.voiceOvers[this.currentlyPlaying].stop() //fade( 0, 1, 100 )
            this.currentlyPlaying = ind
            this.voiceOvers[this.currentlyPlaying].play()
            this.voiceOvers[this.currentlyPlaying].seek(0)

        }
    }
    stopVO() {
        if (this.voiceOvers[this.currentlyPlaying]) this.voiceOvers[this.currentlyPlaying].stop()
        this.currentlyPlaying = null
    }

    onTouchStart = event => {
        const touch = event.touches.item(0);
        this._touch1 = touch;
        this._prevX = touch.clientX;
        this._prevY = touch.clientY;

        this.mouse.x = (touch.clientX / window.innerWidth) * 2 - 1;
        this.mouse.y = - (touch.clientY / window.innerHeight) * 2 + 1;
        clearTimeout(this.cursorOnHubbleTimeOut)
        this.raycast()
    }
    onTouchEnd = event => {
        clearTimeout(this.cursorOnHubbleTimeOut)
        this.cursorOnHubbleTimeOut = setTimeout(_ => {
            this.cursorOnHubble = false
        }, 1000)

    }

    raycast = () => {
        this.raycaster.setFromCamera(this.mouse, this.me.camera);

        // calculate objects intersecting the picking ray
        const intersects = this.raycaster.intersectObjects([this.hubblePlane]);
        if (!intersects || !intersects.length) return this.cursorOnHubble = false

        this.cursorOnHubble = true


    }
    updateInteractionPrompt(dt) {
        const ani = this.interactionPromptAni
        if (!ani.on) return
        ani.progress += dt / ani.duration
        if (ani.progress > 1) ani.progress = 1
        this.promptMat.opacity = 0 // THREE.Math.lerp(ani.from, ani.to, ani.progress)

        if (ani.progress === 1) {
            ani.on = false
            ani.progress = 0
        }
    }
    revealInteractionPrompt(dir) {
        if (dir === 1) {
            document.getElementById("hubbleInstructions").style.opacity = 1
            this.interactionPromptAni = {
                on: true,
                from: this.promptMat.opacity,
                to: 1,
                duration: 0.5,
                progress: 0
            }
        }
        if (dir === -1) {
            document.getElementById("hubbleInstructions").style.opacity = 0
            this.interactionPromptAni = {
                on: true,
                from: this.promptMat.opacity,
                to: 0,
                duration: 0.5,
                progress: 0
            }
           // console.warn('out', this.interactionPromptAni)
        }
    }

    listenToVisibility() {
        // Set the name of the hidden property and the change event for visibility
        var hidden, visibilityChange;
        if (typeof document.hidden !== "undefined") { // Opera 12.10 and Firefox 18 and later support
            hidden = "hidden";
            visibilityChange = "visibilitychange";
        } else if (typeof document.msHidden !== "undefined") {
            hidden = "msHidden";
            visibilityChange = "msvisibilitychange";
        } else if (typeof document.webkitHidden !== "undefined") {
            hidden = "webkitHidden";
            visibilityChange = "webkitvisibilitychange";
        }

        this._hidden = hidden 
        this.visible = true 


        // Warn if the browser doesn't support addEventListener or the Page Visibility API
        if (typeof document.addEventListener === "undefined" || hidden === undefined) {
            console.log("This demo requires a browser, such as Google Chrome or Firefox, that supports the Page Visibility API.");
        } else {
            // Handle page visibility change
            document.addEventListener(visibilityChange, this.onVisibilityChange , false);
        //    window.addEventListener('blur', this.onBlur )
          //  window.addEventListener('focus', this.onFocus  )
        }
    }
    onOrientationChangeCallback = ( landscape ) => {
    //    console.warn('Landscape', landscape)
        this.isLandscape = landscape 
        if ( landscape  ){
            if ( this.voiceOvers && this.voiceOvers[ this.currentlyPlaying ] ) this.voiceOvers[ this.currentlyPlaying ].pause()
            if ( this.bgMusic ) this.bgMusic.pause()
        }
        if ( !landscape  ){
            if ( this.voiceOvers && this.voiceOvers[ this.currentlyPlaying ] )this.voiceOvers[ this.currentlyPlaying ].play()
            if ( this.bgMusic ) this.bgMusic.play()
        }
    }
    onVisibilityChange = _ =>{
        // alert('CHANGE')
        const visible = document[ this._hidden ]
        if (  visible   ){
            if ( this.voiceOvers && this.voiceOvers[ this.currentlyPlaying ] ) this.voiceOvers[ this.currentlyPlaying ].pause()
            if ( this.bgMusic ) this.bgMusic.pause()
        }
        if ( ! visible   ){
            if ( this.voiceOvers && this.voiceOvers[ this.currentlyPlaying ] )  this.voiceOvers[ this.currentlyPlaying ].play()
            if ( this.bgMusic ) this.bgMusic.play()
        }
    }
    onBlur = _=>{
       // console.log('blur')
        this.visible = false 
       
            if ( this.voiceOvers && this.voiceOvers[ this.currentlyPlaying ] )  this.voiceOvers[ this.currentlyPlaying ].pause()
            if ( this.bgMusic )  this.bgMusic.pause()
        
    }
    onFocus = _=>{
        //console.log('focus')
        this.visible = true 
        
        if ( this.voiceOvers && this.voiceOvers[ this.currentlyPlaying ] ) this.voiceOvers[ this.currentlyPlaying ].play()
        if ( this.bgMusic )   this.bgMusic.play()
        
    }

    //called every frame, dt: time passed between frames
    update(dt) {

        super.update(dt)

        if ( this.isLandscape || !this.visible  ){
            this.renderer.pauseRendering = true 
            return 
        } else {
            this.renderer.pauseRendering = false 
        }
        dt = Math.min( dt, 0.048 )

        if (!this.started) return

        let rate = 1 
        if ( window.location.href.match('fast')) rate = 10 
        this.updateTimeline(dt * rate )
        this.updateInteractionPrompt(dt)

      

        // console.log( this.cursorOnHubble )
        // console.log( this.timeline[ this.timelineInd ].name )

        //        this.cons.textContent = inputManager.speedZ 

        this.planetsCont.position.copy(this.particles.parent.position)
        // this.mixer.update( dt )
        this.children[0].cont3D.rotation.z += dt * 0.03
        this.children[1].cont3D.rotation.y -= dt * 0.01
        this.children[2].particles.rotation.y -= dt * 0.01
        this.hubble.scale.setScalar(this.hubbleScale)

        this.earthComponents.clouds.rotation.y += dt * 0.012
        this.earthComponents.earth.rotation.y += dt * 0.01






        this.ringLabels.forEach(l => {
            if (!l.parent) return
            // console.log('reing')
             l.scale.setScalar(0.16 * 1 / l.parent.scale.x)
          
            // l.position.y = l.posY * 1 / l.parent.scale.x
            // if ( l.posX  )   l.position.x = l.posX * 1 / l.parent.scale.x
            // if ( l.posZ  )   l.position.z = l.posZ * 1 / l.parent.scale.x

         
            const label = l 
            const p = label.parent
            this.cont3D.attach(label)
            label.quaternion.copy(this.me.camera.quaternion)
            
            l._parent.attach(label)
            
        })
        let max = -Infinity
        let min = Infinity

        for (let label of this.planetLabels) {
            if ( !label._parent ) continue 
            // const p = label.parent
            // this.cont3D.attach(label)
            // label.quaternion.copy(this.me.camera.quaternion)
            // p.attach(label)
            label._parent.getWorldPosition( v )
            label.position.copy( v )
            label.position.y += label.posY 
            label.scale.setScalar( this.labelScale || 1.8 )
            // label.position.y += label.posY 
            // label.position.x += label.posX 


        }
        for (let label of this.labels2) {
            const p = label.parent
            this.cont3D.attach(label)
            label.quaternion.copy(this.me.camera.quaternion)
            p.attach(label)

        }

        this.children[ 3 ].cont3D.rotation.y += dt * ( this.rotSpeed2 || 0.02 )
        // this.children[ 3 ].cont3D.rotation.x -= dt * 0.8

        if (window.innerWidth < 1025) {
            this.children[1].cont3D.position.x = 0
            this.children[1].cont3D.position.z = -16

            this.children[0].cont3D.position.y = 10.5

        
        } else {

            this.children[1].cont3D.position.x = 3
            this.children[1].cont3D.position.z = -10

            this.children[0].cont3D.position.y = 8.5
        }
        if ( window.innerWidth < 420 && window.innerHeight < 900 ){
            this.hubbleCont.position.y = -7 
        } else {
            // this.hubbleCont.position.y = -5 
        }


        this.hubbleSync.offsetTop = this.hubbleInstructionsOffsetTop
        this.hubbleSync.update()
   



    }

    dispose() {

        this.cont3D.remove(this.mesh)

    }
}


// DO NOT DELETE THIS SHIT

typeManager.registerClass('Comp.Servicenow', ServiceNow);

export default ServiceNow;

