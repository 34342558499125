import * as THREE from 'three';
import { cloud , three, input, data  } from "xx-packages"
import * as xx from 'xx-packages'

import {Text} from 'troika-three-text'
import generatePlanets from '../methods/planets';
import gsap from "gsap";

const { EasingValue }=data
const { cloudClient, typeManager } = cloud
const { inputManager } = input 
const { VisComp, materialManager } = three


function intervalPerc( perc, start, end  ){
    perc = THREE.Math.clamp( perc, 0, 1 )
    if ( perc < start ) return 0
    if ( perc > end ) return 1 
    return  (perc - start ) / ( end - start )
}
function damp( x, y, lambda, dt ) {

	return THREE.Math.lerp( x, y, 1 - Math.exp( - lambda * dt ) );

}

class Supernova extends VisComp {
    constructor() {

        super();

        window.xx = xx 
      window.sn = this 


    }

    async start(doc = null, canvas) {
        super.start();

        const { geometry , material } = this.children[ 0 ].particles 
        this.particles =  this.children[ 0 ].particles 
        this.children[ 0 ].particles.morphTargetInfluences  = [ 0 , 0 ]
        geometry.morphAttributes.position = [];

        const { position } = geometry.attributes 

        const pos2 = []
        const pos3 = []

        const v = new THREE.Vector3()

        for ( let i = 0; i < position.count; i ++ ){
            v.set(
                position.array[ i * 3 + 0 ],
                position.array[ i * 3 + 1 ],
                position.array[ i * 3 + 2 ],
            )
            v.x *= Math.random() * 0.5 
            v.y *= Math.random()
            v.z *= Math.random() * 0.5 
            v.multiplyScalar( 0.5 )

            pos2.push( v.x, v.y, v.z )

            // half the particles explode in an outwards circle, rest in a tight cone perpendicular to the ecliptic plane

            // circle 
            if ( i % 2 ) {
                const scale = Math.random() * 10
                v.x *= scale
                v.z *= scale 
                v.y *= scale / 10 

            } else {
                v.x *= (Math.random() - 0.5 ) * 3
                v.y *= (Math.random() - 0.5 )  * 20
                v.z *=  (Math.random() - 0.5 ) * 3

                const scale = Math.random() * 30
                v.x *= scale * 0.1 
                v.z *= scale * 0.1 
                v.y *= scale 
            }

           

            pos3.push( v.x, v.y, v.z )

        }
        geometry.morphAttributes.position[ 0 ] = new THREE.Float32BufferAttribute( pos2, 3 );
        geometry.morphAttributes.position[ 1 ] = new THREE.Float32BufferAttribute( pos3, 3 );

        material.morphTargets = true 



        this.time = 0 

        const influences = {
            start: 0 , 
            '0': 0,
            '1': 0 ,
            a: 0,
            exposure: 1,
            explosion: 0
        }

        const tl = gsap.timeline({ repeat: Infinity })
        tl.to( influences, { start: 1, duration: 4,})
        tl.to( influences , { '0': 1, duration: 1, start: 2  });
        tl.to( influences , { '1': 1,'0': 0, duration: 0.1, delay: 0, exposure: 2, explosion: 1 });
        tl.to( influences, { a: 1 , duration: 1, exposure: 1, explosion: 0 })
   
        tl.play()

        this.influences = influences


        const base = new THREE.SpriteMaterial({
            transparent: true,
        
            depthTest: false,
            depthWrite: false ,
            transparent: true 
         //   sizeAttenuation: false
        })
  
            
            
        
        this.explosionMat = 				materialManager.build({ 
            base: base, 
            def:base, 
            asset: this.inputs.get('textures_explosion')
        })
        this.sprite = new THREE.Sprite( this.explosionMat )
        this.cont3D.add( this.sprite )
        this.sprite.scale.set( 1.6384, 1, 1)
        this.sprite.scale.multiplyScalar( 20 )
        this.explosionMat.opacity = 0 
    }

 



    //called every frame, dt: time passed between frames
    update(dt) {

        this.time += dt


       this.particles.morphTargetInfluences[ 0 ] = this.influences[ 0 ]
       this.particles.morphTargetInfluences[ 1 ] = this.influences[ 1 ]

       this.particles.rotation.y = this.influences.start * - Math.PI 
       this.particles.scale.setScalar( 1 - this.influences.start * 0.2  )

       this.renderer.renderer.toneMappingExposure = this.influences.exposure
       this.explosionMat.opacity = this.influences.explosion 
  

    }

    dispose() {

        this.cont3D.remove(this.mesh)

    }
}


// DO NOT DELETE THIS SHIT

typeManager.registerClass('Comp.Supernova', Supernova);

export default Supernova;

