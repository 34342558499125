import { cloud, three } from "xx-packages"
import * as THREE from 'three'

import HubbleDomSync from '../hubbleDomSync';
const { extAssetCache, materialManager } = three
const { cloudClient } = cloud

export default async function generateHubble() {


    const doc = cloudClient.getDoc(this.inputs.get("models_hubble"))
    const model = await extAssetCache.load(doc)
    // console.log("MODEL", model)

    this.hubbleCont = new THREE.Group()
    this.cont3D.add(this.hubbleCont)
    this.hubbleCont.add(model)
    this.model = model

    //   this.hubbleCont.rotation.y = Math.PI / 2


    //  model.rotation.y = 0.5
    //  model.rotation.x = 1.36

    this.hubbleCont.scale.setScalar(6)
    this.hubbleCont.position.y = -19 //-7.5
    this.hubbleCont.position.x = 2.5

    this.mixer = new THREE.AnimationMixer(model);
    const clips = model.userData.model.animations;

    this.clipIndex = 0

    this.clip = clips[this.clipIndex]


    this.hubbleDuration = this.clip.duration

    this.hubble = model.getObjectByName('Dummy001')
    this.hubbleScale = this.hubble.scale.x

    this.earth = this.model.getObjectByName('SM_Earth')
    this.earthCont = new THREE.Group()
    this.model.add(this.earthCont)
    this.earthCont.attach(this.earth)
    this.earthCont.rotation.y = Math.PI / 2



    this.action = this.mixer.clipAction(this.clip);
    this.action.clampWhenFinished = true
    this.action.loop = THREE.LoopOnce
    this.action.play();

    this.mixer.update(0)

    this.mixer.addEventListener('finished', e => {
        // console.warn('AXTION FINISHED')
        if (e.direction === 1) this.startNext('mixer')
        else this.startPrevious('mixer')
    })


    this.clipTime = 0


    model.children[1].children[0].name = 'SM_Earth_cont'

    console.log(model.children[1].children[0] )

    const earth = model.children[1].children[0].getObjectByName('SM_Earth_1')
    const atmo = model.children[1].children[0].getObjectByName('SM_Earth_Atmosphere')
    const clouds = model.children[1].children[0].getObjectByName('SM_Earth_Clouds')
    earth.renderOrder = 1
    atmo.renderComp = 3
    clouds.renderOrder = 2

    atmo.visible = false

    // console.warn(earth.scale.x, atmo.scale.x, clouds.scale.x)
    this.earthComponents = {
        earth, atmo, clouds
    }

    // remove earth animation
    this.clip.tracks = this.clip.tracks.filter(t => !t.name.match(/earth/i))

    earth.quaternion.set(
        0,
        -0.7071068286895752,
        0,
        0.7071067094802856
    )

    clouds.quaternion.set(
        0,
        -0.6336590647697449,
        0,
        0.7736124396324158
    )





    const encoding = THREE.sRGBEncoding


    this.scene.renderComp.setProps({ background: this.inputs.get('envMap') });

    let map = this.scene.renderComp.scene.background
    map.encoding = THREE.LinearEncoding
    map.needsUpdate = true

    model.traverse(m => {
        if (!m.material) return

        m.material.envMap = this.renderer.scene.background


        const { material } = m

        if (material.map) material.map.encoding = encoding;
        if (material.emissiveMap) material.emissiveMap.encoding = encoding;
        if (material.map || material.emissiveMap) material.needsUpdate = true;
    })

    this.scene.renderComp.setProps({ background: this.inputs.get('background') });

    //  map = this.scene.renderComp.scene.background
    // map.encoding = THREE.LinearEncoding
    // map.needsUpdate = true 

    const base = new THREE.SpriteMaterial({
        transparent: true,
        alphaTest: 0.5,
        depthTest: false,
        depthWrite: false ,
     //   sizeAttenuation: false
    })
    const promptMat = 
            materialManager.build({ 
                base: base, 
                def:base, 
                asset: this.inputs.get('textures_spinIcon')
            })

    this.promptSprite = new THREE.Sprite( promptMat )
    this.earth.add( this.promptSprite )
    this.promptMat = promptMat

    this.promptSprite.scale.setScalar( 0.5 )
    this.promptSprite.position.y = 0.7 

    this.promptSprite.material.opacity = 0 

    const hubblePlane = new THREE.Sprite(
        new THREE.SpriteMaterial({
            depthTest: false,
            depthWrite: false ,
            opacity: 0,
            transparent: true ,
            color: 0xff0000
        })

    )
    this.hubble.add( hubblePlane)
    this.hubblePlane = hubblePlane 
    this.hubblePlane.scale.setScalar( 20  )


    this.hubbleSync = new HubbleDomSync({
        targetMesh: hubblePlane,
        targetDiv: document.getElementById('hubbleInstructions'),
        camera: this.me.camera ,
        renderComp: this.renderer,
        offsetTop: this.hubbleInstructionsOffsetTop
    })


}


