import * as THREE from 'three';
import { cloud, three, data ,compositions} from 'xx-packages';




// MODEL LOADER
import { GLTFLoader } from "./loaders/GLTFLoader.js"

import Preloader from "./Preloader"
import device from "./Device"


import './webgl/comp'
import './webgl/demos/planets'
import './webgl/demos/supernova'


import './fonts/stylesheet.css';
import './css/variables.css';
import './css/index.css';
import './css/loading.css';
import './css/nav.css';
import './css/sections.css';



const { cloudClient, typeManager } = cloud;
const { AppSingle, modelLoader } = three;
const { queryStringToObject } = data.conversion;




const preloader = new Preloader()



// REGISTER LOADERS

THREE.GLTFLoader = GLTFLoader
modelLoader.registerLoader({
	loader: THREE.GLTFLoader,
	type: "GLTF"
})

const params = queryStringToObject(window.location.search);

/* DEFAULT CONSTANTS - OBSOLATE ******************************************/

const inputManConf = {
	mkb: {
		enabled: false,
		channels: {
			ui: {
				enabled: false,
				count: 2,
			},
		},
	},
	touch: {
		enabled: false,
	},
	gamepad: {
		enabled: false,
	},
	midi: {
		enabled: !!params.forwardMIDI,
	},
	network: {
		enabled: false,
		p2pBrokerUrl: 'wss://vjycloud.herokuapp.com/p2p/',
		peerId: params.peerId,
		connection: {
			peerId: params.remotePeerId,
			retryIntervalSeconds: 10,
			maxRetries: 5,
			forwardMIDI: params.forwardMIDI,
		},
	},
};


const appDecl = {
	settings: {
		cloud: {

		},

		inputMan: inputManConf,

		audioMan: {
			musicMeta: {}
		},
	},

	ui: {
		main: null,
		sys: {
			enabled: false,
			visible: false
		}
	},

	project: {
		doAddHTML: false,
		doAddCSS: false,
		doScroll: false,
		allowSceneHTML: true,
		override: {},
		n: 'Servicenow',
	},
	dominantContext: 'composition'
};

const uiExp = {
	t: 'UIExp',
	d: {
		infoBox: {
			enabled: true
		},
		paramStack: {
			enabled: true,
			elems: [
				{ t: 'effect' },
				{ t: 'color' }
			]
		}
	}
};

if ( window?._params?.compId ){
	params.compId = window._params.compId
} 
if ( window?._params?.project ){
	params.project = window._params.project
}

if ( !document.getElementById('canvas') ){
	const canvas = document.createElement('canvas')
	canvas.id = 'canvas'
	document.body.insertBefore( canvas, document.querySelector('#content') )
}

// if ( !window.location.href.match('61894be57d489d0016396b4c') && !window.location.href.match('=Servicenow')   ){
// 	document.querySelector('#content').innerHTML =''
// 	document.querySelector('canvas').style.zIndex = '1'
// }
(async function () {


	cloudClient.init({
		apiBaseUrl: "https://vjycloud.herokuapp.com/api/v2/",
		storage: window.localStorage,
		//cloudBaseUrl:  '/',
	});

	// const user = await cloudClient.getCurrentUser();


	window.tm = typeManager

	// COMPOSITION PRESET
	// if (params.compId) {
	// 	appDecl.project.id = user.settings.view[">link"].id;
	// 	if (params.project) {
	// 		if (params.project == "View.AV") appDecl.project.id = user.settings.viewAV[">link"].id;
	// 		else appDecl.project.n = params.project;
	// 	}
	// 	appDecl.project.override = {
	// 		scene: {
	// 			startComp: { '>link': { id: params.compId } },
	// 		},
	// 	};
	// 	// PROJECT
	// } else if (params.project) {
		//appDecl.ui.main=uiExp;
		appDecl.project.allowSceneHTML = false;
		appDecl.project.n = 'Servicenow';
		// GEOM > MESH LAB
	// } 
	if (!device.hasWebGL) {
		return preloader.showUnsupportedMessage()
	}

	// console.warn('APP DECL', appDecl)
	const app = new AppSingle(appDecl);
	app.preloader = preloader
	app.device = device
	app.enableScreenshot = false;
	// monitor.setApp( app )








	app.start();
	window.app = app 







	// monitor.initUI()
})();
