import * as THREE from 'three'

let pos = new THREE.Vector3();
const v1 = new THREE.Vector3()
const v3 = new THREE.Vector3()
const v4 = new THREE.Vector3()
export default class HubbleDomSync {
    constructor({
        targetMesh,
        targetDiv,
        camera,
        renderComp,
        offsetTop

    }) {
        this.targetDiv = targetDiv
        this.targetMesh = targetMesh
        this.camera = camera 
        this.renderComp = renderComp
        this.targetMesh.geometry.computeBoundingBox()
        this.offsetTop = offsetTop
        this.bounds = this.targetDiv.getBoundingClientRect()
        window.addEventListener('resize', _=>{
            this.bounds = this.targetDiv.getBoundingClientRect()
        })
    }
    update(){

      
        // get screen coordinates of target mesh 
        pos.setFromMatrixPosition(this.targetMesh.matrixWorld);
        pos.project(this.camera);

        let widthHalf = window.innerWidth / 2;
        let heightHalf = window.innerHeight / 2;

        // convert coordinates to pixels, and account for target div's width / height so that the position we get lets us accurately center the vid
        pos.x = (pos.x * widthHalf) + widthHalf - this.bounds.width / 2 ;
        pos.y = - (pos.y * heightHalf) + heightHalf - this.bounds.height / 2;
        pos.z = 0;

        this.targetMesh.getWorldScale( v1 )
        const meshWidth = ( this.targetMesh.geometry.boundingBox.max.x - this.targetMesh.geometry.boundingBox.min.x ) * v1.x 
        const meshHeight = ( this.targetMesh.geometry.boundingBox.max.y - this.targetMesh.geometry.boundingBox.min.y ) * v1.y 

        this.targetMesh.getWorldPosition( v3 )
        this.camera.getWorldPosition( v4 )
        const distanceToCamera = v3.distanceTo( v4  )
        const dimZ = this.renderComp.screenDimZ( distanceToCamera )

        const meshScreenWidth = meshWidth / dimZ.x * window.innerWidth
        const meshScreenHeight = meshHeight / dimZ.y * window.innerHeight
    
        if ( window.innerWidth > 1025 ){
            let offsetLeft = 0.9
            if ( window.innerHeight > 1000 ) offsetLeft = 0.8 
            this.targetDiv.style.top = pos.y + 'px'
            this.targetDiv.style.left = ( pos.x + offsetLeft * meshScreenWidth ) + 'px'
        } else {
            let offsetTop = 0.5 
            this.targetDiv.style.top =  ( pos.y - meshScreenHeight * this.offsetTop )  + 'px'
            this.targetDiv.style.left = pos.x + 'px'
        }



    
    }

}