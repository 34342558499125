    import * as THREE from 'three'
    // scroll to section on nav / button click
    // sync nav state with currently visible section
    export default function initNavControls() {

        this.onNavButtonClick = onNavButtonClick.bind( this )

        const buttons = [ ... document.querySelectorAll( 'nav ul li') ]
        let current = document.querySelector( 'nav ul li.current')


        this.navButtons = buttons 
        this.currentNavButton = current

        for ( let i = 0; i < buttons.length; i ++ ){
            const button = buttons[ i ]
            const section = document.querySelector('#section' + (i+1))

            if ( !section ) return 

            // scroll to section
            button.addEventListener('click', this.onNavButtonClick )
            button._index = i 
            // sync nav buttons with scroll state
            let options = {
                root: document.body,
                rootMargin: '0px',
                threshold: [0,0.1, 0.5, 1]
            }
            const callback = entries => {
                for (let entry of entries) {
                    if ( entry.intersectionRatio > 0.5 ) {
                        current.classList.remove('current')
                        button.classList.add('current')
                        current = button 
                    }
                    if ( entry.intersectionRatio > 0.99 ) {
                        // console.log('full')
                    }
  
                }
            }
            let observer = new IntersectionObserver(callback, options);
            observer.observe(  section )
        }

        document.querySelector('header').addEventListener('click', _=>{
            // document.body.classList.add('entered')
            // this.entered = true 

            // document.querySelector('#section1').scrollIntoView({ 
            //     behavior: 'smooth'
            // })

            this.startNext(1)
        })
        document.querySelector('footer button').addEventListener('click', _=>{
            const cur = this.timeline[ this.timelineInd ]
            // console.log( this.timelineInd, i )
            if ( cur.type !== 'state' || cur.name !== 'final CTA') return;
            this.transitionning = true 
            const i = 0 
            document.querySelector('#transition').classList.add('active')
            const next = this.timeline.find( el => el.section === i + 1  )
            const nextIndex = this.timeline.findIndex( el => el.section === i + 1  )
            setTimeout(_=>{
                for ( let el of next.transforms ){
                    if ( el.position ) {
                        let { position } = el 
                        if ( typeof position === 'string' ) {
                            position = this.inputs.get('positions_'+position)
                        }
                        el.obj.position.copy(  position )
                    
                    }
                    if ( el.rotation ) {
                        let { rotation } = el 
                        if ( typeof rotation === 'string' ) {
                            //  console.log( 'rositions_'+rotation,this.inputs.get('rotations_'+rotation) ) 
                            rotation = this.inputs.get('rotations_'+rotation)
                            euler.set( rotation.x, rotation.y, rotation.z )
                        }
                        el.obj.rotation.copy( euler )
                        
                    }
                    this.timelineInd = nextIndex

                    if ( cur.onExit ) cur.onExit(0);
                    if ( next.onEnter ) next.onEnter() ;
                    if ( next.onStart ) next.onStart(0) ;
                    if ( next.onJumpTo ) next.onJumpTo()
                }
                this.currentNavButton.classList.remove('current')
                const button = this.navButtons[ 0]
                button.classList.add('current')
                
                this.currentNavButton = button
            }, 500)
            this.revealSection ( i + 1 )
            setTimeout(_=>{
                document.querySelector('#transition').classList.remove('active')
                this.transitionning = false 
           }, 1000 )
        })
    }

    const euler = new THREE.Euler()
    function onNavButtonClick( e ){
        if ( this.transitionning ) return 
        const button = e.target
        const i = button._index 

        const cur = this.timeline[ this.timelineInd ]
        const current = this.currentNavButton
        // console.log( this.timelineInd, i )
        if ( cur.type !== 'state') return;
        const stateInd = cur.section - 1 

        // console.log( stateInd, i )
            if ( i === stateInd + 1   ){
                this.startNext()
            }
            else if ( i ===  stateInd - 1  ){
                this.startPrevious()
            } else {

                this.transitionning = true 

                document.querySelector('#transition').classList.add('active')

                const next = this.timeline.find( el => el.section === i + 1  )
                const nextIndex = this.timeline.findIndex( el => el.section === i + 1  )

                setTimeout(_=>{
                    for ( let el of next.transforms ){
                        if ( el.position ) {
                            let { position } = el 
                            if ( typeof position === 'string' ) {
                                // console.log( 'positions_'+position,this.inputs.get('positions_'+position) )
                                position = this.inputs.get('positions_'+position)
                            }
                            el.obj.position.copy( position )
                        }
                        if ( el.rotation ) {
                            let { rotation } = el 
                            if ( typeof rotation === 'string' ) {
                                //  console.log( 'rositions_'+rotation,this.inputs.get('rotations_'+rotation) ) 
                                rotation = this.inputs.get('rotations_'+rotation)
                                euler.set( rotation.x, rotation.y, rotation.z )
                            }
                            el.obj.rotation.copy( euler )
                            
                        }
                        this.timelineInd = nextIndex

                        if ( cur.onExit ) cur.onExit();
                        if ( next.onEnter ) next.onEnter(0) ;
                        if ( next.onStart ) next.onStart(0) ;
                        if ( next.onJumpTo ) next.onJumpTo()
                    }
                }, 500)
                this.revealSection ( i + 1 )
                setTimeout(_=>{
                    document.querySelector('#transition').classList.remove('active')
                    this.transitionning = false 
               }, 1000 )
            }
        
        current.classList.remove('current')
        button.classList.add('current')
        
        this.currentNavButton = button
    }