import * as THREE from 'three';
import { cloud , three, input, data , ui  } from "xx-packages"
const { inputManager } = input 

const axes = ['x','y','z']





export  function updateTimeline( dt ){
    const current = this.timeline[ this.timelineInd ]

    if ( current.type === 'state') {

        if ( current.onUpdate ) current.onUpdate( dt   )

        if ( this.cursorOnHubble ) return 

        // Input.speedZ is flipped on Firefox


        
        let speedZ  = inputManager.speedZ
        if ( this.isFirefox ) speedZ = - speedZ 
        // mobile - enable side swipe
        if ( inputManager._touch.speedX  ){
            const { speedX } = inputManager._touch
            speedZ = - speedX
        }
        if ( speedZ > 1 ) this.startNext( 'input')
        if ( speedZ < -1 ) this.startPrevious( 'input')
        return 
    }
    const { duration , animations , direction } = current 

    current.perc += direction * dt / duration 

    if ( current.perc < 0 ) current.perc = 0 
    if ( current.perc > 1 ) current.perc = 1 

    const perc = current.perc 

    for ( let key in animations ){
        let { obj, from, to, prop  , easing } = animations[ key ]
        if ( prop === 'position' ){
            if ( typeof from === 'string' ){
                // console.log( from )
                // console.log( 'position_' + from, prop, obj )
                from = this.inputs.get('positions_' + from )
                 
            }
            if ( typeof to === 'string' ){
                to = this.inputs.get('positions_' + to )
            }
        }
        if ( prop === 'rotation' ){
            
            if ( typeof from === 'string' ){
             
                from = this.inputs.get('rotations_' + from )
                 
            }
            if ( typeof to === 'string' ){
                to = this.inputs.get('rotations_' + to )
            }
            
        }
        const prog = easing? easing( perc ) : perc 
        if ( prop  ==='position' ){
            obj[ prop ].lerpVectors( from, to, prog )
        }
        if ( prop  ==='rotation'){
            for ( let axis of axes ){
                obj[ prop ][ axis ] = THREE.MathUtils.lerp( from[axis], to[axis], prog )
            }
            
        }
    }

    if ( current.onUpdate ) current.onUpdate( dt, current.perc  )



    // reached end of animation
    if ( current.perc === 1 ){
        this.startNext( 'end of anim' )
    }
    if ( current.perc === 0 ){
        this.startPrevious( 'end of anim' )
    }

    
}

export function startNext( reason ){

    
    if ( this.transitionning && reason === 'input') return 
    document.getElementById('continueScrolling').style.opacity = 0 

    const swipeToContinue = this.currentSection && this.currentSection.querySelector('.SwipeToContinue')
    if ( swipeToContinue ){
        swipeToContinue.style.opacity = 0 
    }
    const current = this.timeline[ this.timelineInd ]
    const next = this.timeline[ this.timelineInd + 1 ]

    console.log( reason + ' Start next: ' +  current.name + ' to: ' + next?.name )

    if ( current.onEnd ) current.onEnd()

    if ( !next ) return 

    this.transitionning = true 
    clearTimeout( this.waitForNext )
    this.waitForNext = setTimeout( _=>{
        this.transitionning = false
    }, 1000 )

    if ( next.onEnter ) next.onEnter( 1 )
    if ( next.onStart ) next.onStart( 1 )

    this.timelineInd ++ 

    next.direction = 1 
    next.perc = 0 
}

export function startPrevious( reason ){
    if ( this.transitionning && reason === 'input' ) return 
    document.getElementById('continueScrolling').style.opacity = 0 
    const swipeToContinue =  this.currentSection &&  this.currentSection.querySelector('.SwipeToContinue')
    if ( swipeToContinue ){
        swipeToContinue.style.opacity = 0 
    }
    const current = this.timeline[ this.timelineInd ]
    const prev = this.timeline[ this.timelineInd - 1 ]

    console.log( reason + ' Start previous: ' +  current.name + ' to: ' + prev?.name )

    
    if ( current.onPrevious ) current.onPrevious()

    if ( !prev ) return 

    this.transitionning = true 
    clearTimeout( this.waitForNext )
    this.waitForNext = setTimeout( _=>{
        this.transitionning = false
    }, 1000 )


    prev.direction = - 1

    this.timelineInd -- 
    
    if ( prev.onEnter ) prev.onEnter( -1 )
    if ( prev.onStart ) prev.onStart( -1 )

 
}