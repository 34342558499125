import {Howl, Howler} from 'howler';

export default function initAudio(){
    

    var bg = new Howl({
        src: [ this.inputs.get('audio_bgUrl') ],
        autoplay: true,
        loop: true,
        volume: 0.5,
        onend: function() {
          
        }
      });
      bg.play()
      this.bgMusic = bg 
      if ( this.isLandscape ) bg.pause()


      let rate = 1 
      if ( window.location.href.match('fast')) rate = 10 

    this.voiceOvers = this.inputs.get('audio_voUrl').map( (url,i) =>{
        return  new Howl({
            src: [ url ],
            loop: false,
            volume: 1,
            rate,
            onend: _=> {
              this.onVoiceOverEnd( i )
            }
          });
    })
    this.voiceOvers.forEach(element => {
        // element.rate(2)
    }); 
}