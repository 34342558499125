import { three , cloud } from "xx-packages"
import * as THREE from 'three'
import {Text} from 'troika-three-text'
import { createDerivedMaterial } from 'troika-three-utils'

 function createBillboardMaterial(baseMaterial, opts) {
  return createDerivedMaterial(
    baseMaterial,
    Object.assign(
      {
        vertexMainOutro: `
vec4 mvPosition = modelViewMatrix * vec4( 0.0, 0.0, 0.0, 1.0 );
vec3 scale = vec3(
  length(modelViewMatrix[0].xyz),
  length(modelViewMatrix[1].xyz),
  length(modelViewMatrix[2].xyz)
  );
// size attenuation: scale *= -mvPosition.z * 0.2;
mvPosition.xyz += position * scale;
gl_Position = projectionMatrix * mvPosition;
`
      },
      opts
    )
  )
}

const {  materialManager } = three
const { cloudClient, typeManager } = cloud

export default function generatePlanetLabels() {
    const mat = this.inputs.get('textures_planetLabels');
    this.labelMat=[];
    const base = new THREE.SpriteMaterial({
        transparent: true,

        depthTest: false,
        depthWrite: false ,
     //   sizeAttenuation: false
    })
    for( let i=0; i < mat.count ; i++ ) {
        this.labelMat.push( 
            materialManager.build({ 
                base: base, 
                def:base, 
                asset:mat.getNext()
            }))

        const ratio = parseFloat( cloudClient.getDoc( mat.elems[ i ] ).m.fields.ratio )
        this.labelMat[ i ].ratio = ratio 
        this.labelMat[ i ].map.encoding = THREE.sRGBEncoding
        this.labelMat[ i].map.minFilter = THREE.LinearFilter;

        }
        
        
    
    this.connMat = 				materialManager.build({ 
        base: base, 
        def:base, 
        asset: this.inputs.get('textures_connector')
    })
    this.connMat.map.encoding = THREE.sRGBEncoding
    this.connMat.map.minFilter = THREE.LinearFilter;

    const names = [
        'Equipment\nrequests',
        'Annual leave\nrequests',
        'Telework\nrequests',
        'Booking\ntransport',
        'Mission-specific\nsupport',
        'Inventory\nstatus',
        'Technical\nrecords',
        'Archive\nrequests'
    ]

    const labels = []
    for ( let i = 0; i < this.labelMat.length; i ++ ) {

        const myText = new Text()
        // this.cont3D.add(myText)

         myText.material = createBillboardMaterial(
            new THREE.MeshBasicMaterial({
                transparent: true,
        
                depthTest: false,
                depthWrite: false ,
             //   sizeAttenuation: false
            })
         )
        // Set properties to configure:
        myText.text = names[ i ]
        myText.fontSize = 0.2
        myText.textAlign = 'center'
        // myText.material.depthTest = false 
        myText.material.depthWrite = false 

        myText.anchorY = 'center'
        myText.anchorX = 'center'
     

        // Update the rendering:
        myText.sync()

       // const mesh = new THREE.Sprite( mat )
        let mesh = myText
        
        mesh.scale.setScalar(0.05)
        // const mesh = new THREE.Sprite(
        //     this.labelMat[ i ]
        // )
        mesh.ratio = this.labelMat[ i ].ratio
        mesh.text = names[  i ]
        labels.push( mesh )
        this.cont3D.add( mesh )

        const connector = new THREE.Sprite( this.connMat.clone() )
        mesh.connector = connector
       mesh.add( connector )
        connector.scale.set( 0.1, 0.4, 1)

        


       
    }
    this.labels = labels 

    let i = 0 
    for ( let key of ['Mars', 'Neptune', 'Mercury', 'Moon', 'Venus']) {
        let obj = this.planets.getObjectByName( 'SM_' + key )
        const cont  = this.planetContainers.find( el => el.name ===  'SM_' + key + '_cont' )
        if ( key !== 'Moon' ) obj.add( labels[ i ])
        obj.label = labels[ i ]
        labels[i].scale.setScalar( 1 / obj.scale.x )
        const { connector } = labels[ i ]

        if ( i % 2 ){
            connector.rotation.z = Math.PI 
            connector.position.y = 0.1 
            labels[ i ].position.y = - 0.05
            labels[  i ].side = -1
        } else {
            
            connector.position.y = - 0.1 
            labels[ i ].position.y =  0.05
            labels[  i ].side = 1
        }
        // labels[ i ].scale.setScalar( 0.1)
        
        i ++ 
    }

    this.labelRad = 0.002

    for ( let i = 0; i  < this.labels.length ; i ++ ){
       
        const label = this.labels[ i ]
        label.parent.label = label 

        label._parent = label.parent 
        label.posY = 0 
      
        if ( i < 5 ) this.cont3D.add( label )
        if ( i == 0 ){
            label.posY = 3
            label.posX = 0.02
        }
        if ( i == 1 ){
            label.posY = - 2.2
            label.posX = 0.05
        }
        if ( i == 2 ){
            label.posY = 3.6
        }
        if ( i == 3 ){
            label.posY = -2
        }
        if ( i == 4 ){
            label.posY = 2.7
        }

        // label.posY *= 0.8 

        if ( i == 5 ){
            label.posY = 0.22
        }
        if ( i == 6 ){
            label.posY = 0.22
        }
        if ( i == 7 ){
            label.posY = 0.22
        }

        // label.posY = 0 
        // if ( i === 4) label.posY = 0.05 
    }

    for ( let mesh of this.labels ){
        mesh.scale.x *= mesh.ratio 
        const { connector } = mesh 
        const name = mesh.text 
        connector.scale.set( 0.3, 1.2, 1)

        if ( name.match('\n') ) connector.position.y = - 0.9
        else connector.position.y = - 0.7


        mesh.connector.position.y *= Math.sign( mesh.position.y )
        if ( mesh.position.y < 0 ) {
            connector.material.rotation = Math.PI 
            connector.position.y -= 0.5
        }

        mesh.connector.material.depthTest = true 
   
    }
    this.labels.forEach(l =>{
        l.material.opacity = 0
        l.connector.material.opacity = 0 
        
    })

    for ( ; i < 8 ; i ++){
        const obj = this.planets.getObjectByName( 'SM_Saturn_Rings'  )
        obj.add( labels[ i ])
        labels[i]._parent = obj 
        const label = labels[ i ]
        // labels[i].scale.setScalar( 1 / obj.scale.x )
        const { connector } = labels[ i ]

        label.position.x = Math.sin( (i -5) / 3 * Math.PI * 2 ) *  this.labelRad
        label.position.z = Math.cos( (i -5) / 3 * Math.PI * 2 ) *  this.labelRad
        // console.log( label.position )

        label.posZ = label.position.z 
        label.posX = label.position.x 
        label.posY = 0 

        // if ( i % 2 ){
        //     connector.rotation.z = Math.PI 
        //     connector.position.y = 0.1 
        //     labels[ i ].position.y = - 0.05
        //     labels[  i ].side = -1
        // } else {
            
            connector.position.y = - 0.9
            labels[ i ].position.y =  this.labelRad * 0.45
            labels[  i ].side = 1
        // }
        labels[ i ].scale.setScalar( 0.1)
    }

    this.ringLabels = this.labels.filter ( (_, i )=> i > 4 )
    this.planetLabels = this.labels.filter ( (_, i )=> i < 5 )


    if ( window.innerWidth < 1025 ){
        this.children[ 1 ].cont3D.position.x = 0 
        this.children[ 1 ].cont3D.position.z = -16 

        this.children[ 0 ].cont3D.position.y = 10.5
    } else {

        this.children[ 1 ].cont3D.position.x = 3 
        this.children[ 1 ].cont3D.position.z = -10 

        this.children[ 0 ].cont3D.position.y = 8.5
    }


    const labels2 = []
    const names2 = [
        'Helpdesks', 'Email\nrequests', 'Phone\nrequests', 'Paper\nforms' 
    ]
    const positions = [
        new THREE.Vector3( -3, 3 , 0 ),
        new THREE.Vector3( -1.5, -3.5 , 0 ),
        new THREE.Vector3( 1.5, -3 , -1 ),
        new THREE.Vector3( 3, 3 , 0.5 )
    ]

    i = 0 
    for ( let name of names2  ) {

        // const m= new THREE.Mesh( new THREE.BoxBufferGeometry(), new THREE.MeshBasicMaterial())
        // this.particles.add( m)
        const myText = new Text()

       
            myText.material = createBillboardMaterial(
                new THREE.MeshBasicMaterial({
                    transparent: true,
            
                    depthTest: false,
                    depthWrite: false ,
                 //   sizeAttenuation: false
                })
             )


        // Set properties to configure:
        myText.text = name
        myText.textAlign = 'center'
        myText.fontSize = 0.17

        myText.material.depthTest = false 
        myText.material.depthWrite = false 

        myText.anchorY = 'center'
        myText.anchorX = 'center'
     
        myText.material.opacity = 0 
        // Update the rendering:
        myText.sync()

       // const mesh = new THREE.Sprite( mat )
        let mesh = myText
        labels2.push( mesh )
        mesh.scale.setScalar(3)

        this.particles.add(myText)

        mesh.position.copy( positions[ i ])
        mesh.desktopPosition = mesh.position.copy 

        i ++ 

        const connector = new THREE.Sprite( this.connMat.clone() )

        // connector.material.map = null
        // connector.material.color = new THREE.Color(0xff0000)
        connector.material.depthTest = false 
        connector.material.depthWrite = false 
        mesh.connector = connector
        mesh.add( connector )
        connector.scale.set( 0.2, 0.8, 1)

        connector.scale.multiplyScalar( 1.3 )

        if ( name.match('\n') ) connector.position.y = - 0.9
        else connector.position.y = - 0.7


        mesh.connector.position.y *= Math.sign( mesh.position.y )
        if ( mesh.position.y < 0 ) {
            connector.material.rotation = Math.PI 
            connector.position.y -= 0.5
        } else  connector.position.y += 0.1
   
        this.cont3D.attach( myText )

        myText.worldPos = myText.position.clone()


       
    }

    this.labels2 = labels2 
}

