import * as THREE from 'three'
import { data , input} from 'xx-packages'



const { inputManager } = input 
const { EasingFunctions } = data 
/**
 * - scroll / lick triggers anim to next or previous
 * - app is either in transition or in state
 */

 let mixerSpeed = 1
 if ( window.location.href.match('fast') ) mixerSpeed = 10 


const v = new THREE.Vector3()
const v2 = new THREE.Vector3()
function createTimeline(comp) {
    return [

        // start screen
        {
            type: 'state',
            name: 'start screen',
            section: 0,
            dom: document.querySelector('header'),
            transforms: [
                {
                    obj: this.me.camera,
                    position: 'camera_0_start',
                    rotation: 'camera_0_start',
                },
                {
                    obj: this.hubbleCont,
                    position: new THREE.Vector3(0, -19, -10),
             
                }
                
            ],
            onUpdate: _=>{
                // this.me.camera.position.set(0, 0, 25)
                // this.me.camera.rotation.set( 0.3, 0, 0) 
            },
            onExit: _ => {
                document.body.classList.add('entered')


            },
            onEnter: _ => {
                document.body.classList.remove('entered')
                this.children[ 0 ].particles.frustumCulled = false 

            },
            onJumpTo: _=>{
                this.resetSupernova()
                this.stopVO()
                this.promptSprite.material.opacity = 0
                this.children[2].particles.material.opacity = 0
                document.getElementById("hubbleInstructions").style.opacity = 0
                
            }
        },
        // scroll stat screen away
        // zoom camera in 
        {
            type: 'transition',
            name: 'camera in',
            perc: 0,
            duration: 1,
            animations: [
                {
                    obj: this.me.camera,
                    prop: 'position',
                    from: 'camera_0_start',
                    to: 'camera_1_galaxy',
                    easing: EasingFunctions.Quadratic.InOut

                }
            ],
            onStart: dir  => {
                document.body.classList.add('entered')
                this.promptSprite.material.opacity = 0
                this.children[ 0 ].particles.frustumCulled = false 

                if ( dir === -1 ) this.hideFirst()
               

            },
            onUpdate: (dt, perc) => { 
               
                // document.querySelector('header').style.transform = `translate3d(0,-${perc* 200}%,0)`
            },
            onPrevious: _=>{
                
               // document.querySelector('header').scrollIntoView()
            },
            onEnd: _ => { }

        },
        {
            type: 'state',
            name: 'star bg',
            dom: document.querySelector('#section1'),
            section: 1,
            onJumpTo: _=>{
                this.resetSupernova()
                this.stopVO()
                this.promptSprite.material.opacity = 0
                this.children[2].particles.material.opacity = 0
                this.revealSection( 1 )
                document.getElementById("hubbleInstructions").style.opacity = 0
            },
            onEnter: _ => { 
                this.mixer.setTime( 0 )
                this.action.reset()
                this.children[ 0 ].particles.frustumCulled = false 
                this.promptMat.opacity = 0 
                this.revealSection( 1 )
            },
            onExit: _ => { },
            onUpdate: (dt, perc) => { },
            transforms: [
                {
                    obj: this.me.camera,
                    position: 'camera_1_galaxy',
                    rotation: 'camera_1_galaxy',
                },
                {
                    obj: this.hubbleCont,
                    position: new THREE.Vector3(0, -19, -10),
             
                }
            ]
        },
        {
            type: 'transition',
            name: 'hubble in',
            perc: 0,
            duration: 1,
            animations: [
                {
                    obj: this.me.camera,
                    prop: 'position',
                    from: 'camera_1_galaxy',
                    
                    to: 'camera_2_hubble',
                    easing: EasingFunctions.Quadratic.InOut

                },
                {
                    obj: this.hubbleCont,
                    prop: 'position',
                    from: new THREE.Vector3(0, -19, -10),
                    to: new THREE.Vector3(0, -5, -10),
                    easing: EasingFunctions.Quadratic.InOut

                },
                {
                    obj: this.me.camera,
                    prop: 'rotation',
                    from: 'camera_1_galaxy',
                    
                    to: 'camera_2_hubble',
                    easing: EasingFunctions.Quadratic.InOut

                }
            ],
            onJumpTo: _=>{
                document.getElementById("hubbleInstructions").style.opacity = 0
            },
            onStart: dir  => {
                if ( dir === 1 ) this.playVO( 0 )
                // if ( dir === 1 ) this.promptSprite.material.opacity = 0
                // if ( dir === -1 ) this.promptSprite.material.opacity = 1
                if ( dir === -1 ){
                    this.timeline[ this.timelineInd ].perc = 1
                }
             
                this.children[ 0 ].particles.frustumCulled = false 

               // document.querySelector('#section2').scrollIntoView()
                this.revealSection( 2 )
                document.getElementById("hubbleInstructions").style.opacity = 0

            },
            onPrevious: _=>{
                this.revealSection( 1 )
                // document.querySelector('#section1').scrollIntoView()
                
                

            },
            onUpdate: (dt, perc) => {
                this.mixer.update(dt *  mixerSpeed )
               
             

             
            },
            onEnd: _ => { }

        },
        {
            type: 'transition',
            name: 'hubble anim',
            perc: 0,
            duration: 1000,
            animations: [
            ],
            onJumpTo: _=> {
                document.getElementById("hubbleInstructions").style.opacity = 0
            },
            onStart: _ => {

                if ( this.timeline[ this.timelineInd ].direction === - 1 ) {
                    this.startPrevious()
                }
                this.children[ 0 ].particles.frustumCulled = false 
                document.getElementById("hubbleInstructions").style.opacity = 0
            },
  
            onUpdate: (dt, perc) => {
                this.mixer.update(dt *  mixerSpeed   )
                if ( window.innerWidth > 1025 ) return;
                this.hubble.position.y += this.hubbleOffsetY 

                this.hubble.position.y = Math.min( 1.2574203252792358, this.hubble.position.y )
             

             
            },
            onEnd: _ => { }

        },
        {
            type: 'state',
            name: 'control hubble',
            dom: document.querySelector('#section2'),
            section: 2,
            transforms: [
                {
                    obj: this.me.camera,
                    position: 'camera_2_hubble',
                    rotation:  'camera_2_hubble',
                },
                {
                    obj: this.hubbleCont,
                    position: new THREE.Vector3(0, -5, -10),
             
                }
            ],
            onEnter: dir => {

                this.me.ctrl._rotX.current = 0 
                this.me.ctrl._rotX.target = 0 

                this.me.ctrl._rotY.current = 0 
                this.me.ctrl._rotY.target = 0 
                
                this.me.ctrl._targetRotX = 0 
                this.me.ctrl._targetRotY = 0 
                this.me.ctrl.setActive(true)
                this.revealSection( 2 )
                this.resetSupernova()
                this.resetPlanets()
               
                if ( dir === 0  )  this.playVO( 0 )
                if ( dir < 0 ) this.stopVO()

                this.revealInteractionPrompt( 1 )


               

                
                // document.querySelector('#section2').scrollIntoView()
            },
            onExit: _ => {
                this.me.ctrl.setActive(false)
                this.revealInteractionPrompt( - 1 )
            },
            onJumpTo: _=>{
                this.action.reset()
                this.mixer.setTime( this.mixer._actions[ 0 ]._clip.duration - 0.001 )
                this.resetSupernova()
                this.resetPlanets()
                this.playVO( 0 )
                
                this.children[2].particles.material.opacity = 0

                if ( window.innerWidth < 1025 ) this.hubble.position.y = 1.1074203252792358 + this.hubbleOffsetY
                else this.hubble.position.y = 1.1074203252792358
            },
            onUpdate: (dt, perc) => {


                if ( window.innerWidth < 1025 ) this.hubble.position.y = 1.1074203252792358 + this.hubbleOffsetY
                else this.hubble.position.y = 1.1074203252792358
     
                if ( inputManager.lookX || inputManager.lookY ){
                    if ( !this.interactionPromptAni.on ){
                        this.revealInteractionPrompt( - 1 )
                    }
                }
                
            },
            onPrevious: _=>{
               
            }
        },
        {
            type: 'transition',
            name: 'down to galaxy',
            perc: 0,
            duration: 1,
            animations: [
                {
                    obj: this.me.camera,
                    prop: 'position',
                    from: 'camera_2_hubble'
                    ,
                    to: 'camera_3_supernova',
                    easing: EasingFunctions.Quadratic.InOut

                },
                
            ],
            onJumpTo: _=>{
                document.getElementById("hubbleInstructions").style.opacity = 0
            },
            onStart: dir => {
                if ( dir === 1  ){
                    this.playVO( 1 )
                    this.timeline[ this.timelineInd ].perc = 0
                }
                if ( dir === -1 ){
                    this.timeline[ this.timelineInd ].perc = 1
                }
             
                this.currentlyPlaying = 1
                this.revealSection( 3 ) 
                this.children[ 0 ].particles.frustumCulled = true 
                document.getElementById("hubbleInstructions").style.opacity = 0
                //console.log('down to galaxy start', this.timeline[ this.timelineInd ].perc )
                // document.querySelector('#section3').scrollIntoView()

            },
            onUpdate: (dt, perc) => {
                
                this.labels2.forEach(  l => {
                    l.material.opacity =  perc 
                    l.connector.material.opacity =  perc 
                })
               
                this.children[0].particles.frustumCulled = false


                if ( window.innerWidth < 1025 ) this.hubble.position.y = 1.1074203252792358 + this.hubbleOffsetY
                else this.hubble.position.y = 1.1074203252792358
                
            },
            onEnd: _ => { 
                this.children[0].particles.frustumCulled = true
            }

        },
        {
            type: 'state',
            name: 'view galaxy',
            section: 3,
            dom: document.querySelector('#section3'),
            transforms: [
                {
                    obj: this.me.camera,
                    position: 'camera_3_supernova',
                    rotation: 'camera_3_supernova',
                },
                {
                    obj: this.hubbleCont,
                    position: new THREE.Vector3(0, -5, -10),
             
                }
            ],
            onEnter: dir => {
                this.revealSection( 3 )
                if ( dir === 0 ) this.playVO( 1 )
                if ( dir < 0 ) this.stopVO()
                this.children[ 0 ].particles.frustumCulled = true 
                this.promptMat.opacity = 0 
                this.action.reset()
                this.mixer.setTime( this.mixer._actions[ 0 ]._clip.duration - 0.001 )
                // document.querySelector('#section3').scrollIntoView()
                document.getElementById("hubbleInstructions").style.opacity = 0
            },
            onExit: _ => {
                document.getElementById("hubbleInstructions").style.opacity = 0
            },
            onUpdate: (dt, perc) => {
          
            },
            onJumpTo: _=>{
                this.resetSupernova()
                this.planets.visible = false
                this.labels2.forEach(  l => {
                    l.material.opacity = 1 
                    l.connector.material.opacity = 1 
                })
                this.promptSprite.material.opacity = 0
                this.children[2].particles.material.opacity = 0
                document.getElementById("hubbleInstructions").style.opacity = 0
            }
        },
        {
            type: 'transition',
            name: 'explode supernova',
            perc: 0,
            duration: 18.12,
            animations: [
            
                
            ],
            onStart: dir => {
                // if ( this.timeline[ this.timelineInd ].direction === - 1 ) {
                //     this.startPrevious()
                //     return 
                // }
                document.getElementById("hubbleInstructions").style.opacity = 0
                if ( dir === 1 ){
                    this.playVO( 2 )
                    
                }
                if ( dir === -1 ){
                    this.timeline[ this.timelineInd ].perc = 1
                }

                    this.revealSection( 4 )
                // document.querySelector('#section4').scrollIntoView()
                
               

                if ( dir === -1 ) {
                    if ( this.supernovaTimeline ) this.supernovaTimeline.perc =  0.999
                 
                    this.particles.morphTargetInfluences[ 2 ] = 0 
                    console.warn('supernova backwards')
                    this.startSupernova( - 1 )
                    this.updateSupernova( 0 )
                } else {
                    this.startSupernova()
                }

                this.timeline[ this.timelineInd ].duration = this.supernovaTimeline.duration
                this.promptSprite.material.opacity = 0
                // this.planets.scale.setScalar( 10 )
                // this.planets.position.set(0,-8,0)
                
                this.children[ 0 ].particles.frustumCulled = true 


            },
            onUpdate: (dt, perc) => {
              //  console.log( 'explode', perc )

                const p = THREE.MathUtils.clamp( ( perc - 0.8 ) * 5 , 0, 1 ) 
                this.labels2.forEach(  l => {
                    l.material.opacity = 1 - p
                    l.connector.material.opacity= 1 - p
                })
                this.updateSupernova( dt )

                if ( this.timeline[ this.timelineInd ].direction === -1 ) {
                    
                    const prog = THREE.MathUtils.mapLinear( perc, 1, 0.9, 10, 0.00001)
                    const scale = THREE.MathUtils.clamp(  prog  , 0.0000001, 10 )
                    this.planets.scale.setScalar(  scale)
                }
                

             
            },
            onEnd: _ => { 
                this.supernovaTimeline.onEnd()
            }

        },
        {
            type: 'state',
            name: 'after supernova',
            section: 4,
            dom: document.querySelector('#section4'),
            onJumpTo: _=>{
                document.getElementById("hubbleInstructions").style.opacity = 0
                this.planetMixer.setTime( this.planetStartTime )
                this.planets.visible = true
                this.labels2.forEach(  l => {
                    l.material.opacity = 0 
                    l.connector.material.opacity = 0 
                })
                this.labels.forEach(  l => {
                    l.material.opacity = 0 
                    l.connector.material.opacity = 0 
                })
                this.promptSprite.material.opacity = 0
               
                this.particles.scale.setScalar(0.8)
                this.particles.morphTargetInfluences[0] = 0
                this.particles.morphTargetInfluences[1] =  1
                this.particles.morphTargetInfluences[2] =  1

                this.children[ 0 ].particles.frustumCulled = true 
                this.children[2].particles.material.opacity = 0

                this.planetAction.reset()
                this.planets.scale.setScalar( 10 )
                this.planetMixer.setTime( this.planetStartTime )
            },
            transforms: [
                {
                    obj: this.me.camera,
                    position: 'camera_3_supernova',
                    rotation: 'camera_3_supernova',
                },
                {
                    obj: this.hubbleCont,
                    position: new THREE.Vector3(0, -5, -10),
             
                }
            ],
            onEnter: dir => {
                this.revealSection( 4 )
                if ( dir === 0 ) this.playVO(2)
                if ( dir < 0 ) this.stopVO()

                this.labels.forEach(  l => {
                    l.material.opacity = 0 
                    l.connector.material.opacity = 0 
                })
                // document.querySelector('#section4').scrollIntoView()
            },
            onExit: _ => {
                
            },
            onUpdate: (dt, perc) => {

                this.labels.forEach(  l => {
                    l.material.opacity = 0 
                    l.connector.material.opacity = 0 
                })
                
                if ( this.timeline[ this.timelineInd ].direction === 1 ){
                    this.planets.visible = true 
                    this.planets.scale.setScalar( 10 )
                    this.planetAction.reset()
                    
                    this.planetMixer.setTime( this.planetStartTime )
                    this.planets.attach( this.planets.getObjectByName('SM_Moon') )
                } else {
                    this.planets.rotation.y -= dt * 0.1

                    this.planetContainers.forEach( (c,i)=> c.rotation.y -= dt * c.rotSpeed )
    
                    this.planets.getObjectByName('SM_Mars').attach( this.planets.getObjectByName('SM_Moon') )
                }

            }
        },
        {
            type: 'transition',
            name: 'planets in',
            perc: 0,
            duration: 1000000,
            animations: [
                // {
                //     obj: this.me.camera,
                //     prop: 'position',
                //     from: new THREE.Vector3(0, -27, 4)
                //     ,
                //     to: new THREE.Vector3(19, -27, 4)

                // },
                
            ],
       
  
 
            onStart: dir => {
                document.getElementById("hubbleInstructions").style.opacity = 0
                if ( dir === 1 ){
                    this.playVO( 3 )
                    this.planetAction.reset()
                    this.planetMixer.setTime( this.planetStartTime )
                }
                
                this.planets.visible = true 

                this.revealSection( 5 )

                this.planets.scale.setScalar( 10 )
                // document.querySelector('#section5').scrollIntoView()

                if ( this.timeline[ this.timelineInd ].direction  === -1){
                    this.timeline[ this.timelineInd ].duration = 1 
                    this.timeline[ this.timelineInd ].perc = 1 
                } else  this.timeline[ this.timelineInd ].duration = 1000

                if ( this.timeline[ this.timelineInd ].direction  === -1){
                  //  this.planetAction.reset()
                  //  this.planetMixer.setTime( this.planetAction._clip.duration - 0.01 )
                } else {
                    this.planetAction.reset()
                    this.planetMixer.setTime( this.planetStartTime )
                }
                this.promptSprite.material.opacity = 0
                // if ( this.timeline[ this.timelineInd ].direction === - 1 ) this.startPrevious()
                

            },
            onUpdate: (dt, perc) => {
               

                if ( this.timeline[ this.timelineInd ].direction === 1) {
                    this.planets.attach( this.planets.getObjectByName('SM_Moon') )
                    this.planetMixer.update(dt  *      mixerSpeed * 3  )

                    
                } else {
                    this.planets.rotation.y -= dt * 0.1

                    this.planetContainers.forEach( (c,i)=> c.rotation.y -= dt * c.rotSpeed )
    
                    this.planets.getObjectByName('SM_Mars').attach( this.planets.getObjectByName('SM_Moon') )
                }
  
               
             

             
            },
            onEnd: _ => {
              

             }

        },
        {
            type: 'state',
            name: 'planets idle ',
            transforms: [
                {
                    obj: this.me.camera,
                    position: 'camera_3_supernova',
                    rotation: 'camera_3_supernova',
                },
                {
                    obj: this.hubbleCont,
                    position: new THREE.Vector3(0, -5, -10),
             
                }
            ],
            section: 5,
            dom: document.querySelector('#section5'),
            onEnter: dir  => {
                this.revealSection( 5 )
                if ( dir === 0 ) this.playVO( 3 )
                if ( dir < 0 ) this.stopVO()
                this.children[ 0 ].particles.frustumCulled = true 
                document.getElementById("hubbleInstructions").style.opacity = 0
                // document.querySelector('#section5').scrollIntoView()
            },
            onExit: _ => {
                
            },
            onJumpTo: _=>{
                this.planetAction.reset()
                this.planetMixer.setTime( this.planetMixer._actions[ 0 ]._clip.duration - 0.001 )
                this.planets.visible = true 
                this.planets.scale.setScalar( 10 )
                this.labels2.forEach(  l => {
                    l.material.opacity = 0 
                    l.connector.material.opacity = 0 
                })
                this.resetSupernova( true )
                this.labels.forEach(l => {
                    l.material.opacity = 0
                    l.connector.material.opacity = 0 
                })
                this.promptSprite.material.opacity = 0
                this.children[2].particles.material.opacity = 0
                document.getElementById("hubbleInstructions").style.opacity = 0
            },
            onUpdate: (dt, perc) => {
                this.labels.forEach(  l => {
                    l.material.opacity = 0 
                    l.connector.material.opacity = 0 
                })
                if ( !this.stopPlanets )   this.planets.rotation.y -= dt * 0.1

                if ( !this.stopPlanets )  this.planetContainers.forEach( (c,i)=> c.rotation.y -= dt * c.rotSpeed )

                this.planets.getObjectByName('SM_Mars').attach( this.planets.getObjectByName('SM_Moon') )
               
            }
        },
        {
            type: 'transition',
            name: 'planets labels',
            perc: 0,
            duration: 1,
            animations: [
                // {
                //     obj: this.me.camera,
                //     prop: 'position',
                //     from: new THREE.Vector3(0, -27, 4)
                //     ,
                //     to: new THREE.Vector3(19, -27, 4)

                // },
                
            ],
            onStart: dir => {
 
                if ( dir === 1) this.playVO( 4 )
                this.labelsVisible = true 
                this.revealSection( 6 )
                // document.querySelector('#section6').scrollIntoView()
                this.children[ 0 ].particles.frustumCulled = true 
                document.getElementById("hubbleInstructions").style.opacity = 0

            },
            onUpdate: (dt, perc) => {
                if ( this.currentlyPlaying === 3  && this.playedVos[ 3 ] ){
   
                }
                this.labels.forEach(l => {
                    l.material.opacity = perc 
                    l.connector.material.opacity = perc 
                })
 
             
                this.planets.rotation.y -= dt * 0.1


                this.planetContainers.forEach( (c,i)=> c.rotation.y -= dt * c.rotSpeed )

                this.planets.getObjectByName('SM_Mars').attach( this.planets.getObjectByName('SM_Moon') )
             
            },
            onEnd: _ => { }

        },
        {
            type: 'state',
            name: 'planets idle with labels ',
            section: 6,
            dom: document.querySelector('#section6'),
            transforms: [
                {
                    obj: this.me.camera,
                    position: 'camera_3_supernova',
                    rotation:  'camera_3_supernova',
                }   ,
                            {
                    obj: this.hubbleCont,
                    position: new THREE.Vector3(0, -5, -10),
             
                }
            ],
            onEnter: dir => {
                this.revealSection( 6 )
                if ( dir === 0  ){
                    this.playVO( 4 )
                    
                }
                if ( dir < 0 ) this.stopVO()

                this.children[ 0 ].particles.frustumCulled = true 
                // document.querySelector('#section6').scrollIntoView()
            },
            onJumpTo: _=>{
                this.labels.forEach(l => {
                    l.material.opacity = 1
                    l.connector.material.opacity = 1 
                })
                this.labels2.forEach(  l => {
                    l.material.opacity = 0 
                    l.connector.material.opacity = 0 
                })
                this.planets.visible = true 
                this.planetAction.reset()
                this.planets.scale.setScalar( 10 )
                this.planetMixer.setTime( this.planetMixer._actions[ 0 ]._clip.duration - 0.001 )
                this.resetSupernova( true )
                this.promptSprite.material.opacity = 0
                this.children[2].particles.material.opacity = 0
                document.getElementById("hubbleInstructions").style.opacity = 0
            },
            onExit: _ => {
                
            },
            onUpdate: (dt, perc) => {
               
                this.labelsVisible = true 
                if ( !this.stopPlanets ) this.planets.rotation.y -= dt * 0.1


                if ( !this.stopPlanets )   this.planetContainers.forEach( (c,i)=> c.rotation.y -= dt * c.rotSpeed )

                this.planets.getObjectByName('SM_Mars').attach( this.planets.getObjectByName('SM_Moon') )

                
                this.me.camera.getWorldPosition( v2 )
                for ( let label of this.labels ){
                   
                    label.getWorldPosition( v )
                    const d = v.distanceTo( v2 )
                   
                    if ( d < this.distances_closestLabel ){

                         
                        label.material.opacity =  1 - ( this.distances_furthestLabel - d  ) 
                        label.connector.material.opacity = 1 - ( this.distances_furthestLabel - d  ) 
                    }
                    else if ( d > this.distances_furthestLabel ){
                         label.material.opacity = 1 + ( this.distances_furthestLabel - d  ) 
                         label.connector.material.opacity = 1 + ( this.distances_furthestLabel - d  )   
                    } else {
                        label.material.opacity = 1
                        label.connector.material.opacity = 1
                    }
                    label.material.opacity = THREE.MathUtils.clamp( label.material.opacity, 0, 1)
                    label.connector.material.opacity = THREE.MathUtils.clamp( label.material.opacity, 0, 1)
                   
                }
            }
        },
        {
            type: 'transition',
            name: 'to last quote',
            perc: 0,
            duration: 1,
            animations: [
                {
                    obj: this.me.camera,
                    prop: 'position',
                    from: 'camera_3_supernova'
                    ,
                    to: 'camera_4_end',
                    easing: EasingFunctions.Quadratic.InOut

                },
                {
                    obj: this.me.camera,
                    prop: 'rotation',
                    from: 'camera_3_supernova'
                    ,
                    to: 'camera_4_end',
                    easing: EasingFunctions.Quadratic.InOut

                },
                
            ],
            onStart: dir => {
           
                document.getElementById("hubbleInstructions").style.opacity = 0
                if ( this.voiceOvers[ this.currentlyPlaying ] ) {
                    this.voiceOvers[ this.currentlyPlaying ].fade( 1, 0, 200)
                    this.currentlyPlaying = null 
                    
                }
                if ( dir === -1 ){
                    this.timeline[ this.timelineInd ].perc = 1
                }

                this.revealSection( 7 )


                if ( dir === 1){
                    document.body.classList.add('last')
                }
                if ( dir === -1){
                    document.body.classList.remove('last')
                }
                // document.querySelector('#section7').scrollIntoView()
                

            },
            onUpdate: (dt, perc) => {
                this.labelsVisible = true 
                this.planets.rotation.y -= dt * 0.1

                this.children[2].particles.material.opacity = perc 

                this.labels.forEach(l => {
                    l.material.opacity = 1 - perc 
                    l.connector.material.opacity = 1  - perc 
                })
            },
            onEnd: _ => { }

        },
        {
            type: 'state',
            name: 'final CTA',
            transforms: [
                {
                    obj: this.me.camera,
                    position: 'camera_4_end',
                    rotation: 'camera_4_end',
                },
                {
                    obj: this.hubbleCont,
                    position: new THREE.Vector3(0, -5, -10),
             
                }
            ],
            dom: document.querySelector('#section7'),
            section: 7,
            onEnter: _ => {
                this.revealSection( 7 )
                if ( this.voiceOvers[ this.currentlyPlaying ] ) {
                    this.voiceOvers[ this.currentlyPlaying ].fade( 1, 0, 200)
                    this.currentlyPlaying = null 
                    
                }
                this.children[ 0 ].particles.frustumCulled = true 

                this.labels.forEach(l => {
                    l.material.opacity = 0
                    l.connector.material.opacity = 0 
                })
                this.children[ 2 ].particles.material.opacity = 1
                document.getElementById("hubbleInstructions").style.opacity = 0
                // document.querySelector('#section7').scrollIntoView()
            },
            onExit: dir => {
                if ( dir === 0 ) document.body.classList.remove('last') 
            },
            onUpdate: (dt, perc) => {
                this.planets.rotation.y -= dt * 0.1
            },
            onJumpTo: _=>{
                this.labels.forEach(l => {
                    l.material.opacity = 0
                    l.connector.material.opacity = 0 
                })
                this.labels2.forEach(  l => {
                    l.material.opacity = 0 
                    l.connector.material.opacity = 0 
                })
                this.planets.visible = true 
                this.planetAction.reset()
                this.planets.scale.setScalar( 10 )
                this.planetMixer.setTime( this.planetMixer._actions[ 0 ]._clip.duration - 0.001 )
                this.resetSupernova( true )
                this.promptSprite.material.opacity = 0

                this.children[2].particles.material.opacity = 1

                
                document.body.classList.add('last')
                this.children[ 2 ].particles.material.opacity = 1
                document.getElementById("hubbleInstructions").style.opacity = 0
                
            }
        },
    ]
}


export default createTimeline