import * as THREE from 'three';
import { cloud , three, input, data  } from "xx-packages"
import * as xx from 'xx-packages'

import {Text} from 'troika-three-text'
import generatePlanets from '../methods/planets';


const { EasingValue }=data
const { cloudClient, typeManager } = cloud
const { inputManager } = input 
const { VisComp, materialManager } = three


function intervalPerc( perc, start, end  ){
    perc = THREE.Math.clamp( perc, 0, 1 )
    if ( perc < start ) return 0
    if ( perc > end ) return 1 
    return  (perc - start ) / ( end - start )
}
function damp( x, y, lambda, dt ) {

	return THREE.Math.lerp( x, y, 1 - Math.exp( - lambda * dt ) );

}

class ServiceNowPlanets extends VisComp {
    constructor() {

        super();
        this.update = this.update.bind(this)

        this.generatePlanets = generatePlanets.bind(this)

        window.p = this
        window.xx = xx 
      


    }

    async start(doc = null, canvas) {
        super.start();



        const ambient = new THREE.AmbientLight(0xffffff, 0.3 )
        const dir = new THREE.DirectionalLight(0xffffff, 2.5 )
        dir.position.set(0.5, 0, 0.866);

        const dir2 = new THREE.DirectionalLight(0xffffff, 2.5 )
        dir2.position.set(0, 0, 40);
        this.me.camera.add( dir2 )
        this.cont3D.add( this.me.camera )
        // this.renderer.renderer.setClearColor( 0xffffff)


        console.log('Model Preview comp', this)

        this.easedScroll = 0 


        this.doc = doc


        this.rotSpeed = 0.002

        await Promise.all( [
           
             this.generatePlanets()
         ])
 
         this.planets.position.set(0,-3,0)

         this.generateLabels()

        //  this.planetAction.loop = THREE.LoopRepeat
         this.labelRad = 2
       


    }

    generateLabels = _=>{
        const mat = this.inputs.get('labels');
		this.labelMat=[];
        const base = new THREE.SpriteMaterial({
            transparent: true,
            alphaTest: 0.5,
            depthTest: false,
            depthWrite: false ,
         //   sizeAttenuation: false
        })
		for( let i=0; i < mat.count ; i++ ) {
			this.labelMat.push( 
				materialManager.build({ 
					base: base, 
					def:base, 
					asset:mat.getNext()
				}))

            }
            
            
		
        this.connMat = 				materialManager.build({ 
            base: base, 
            def:base, 
            asset: this.inputs.get('connector')
        })

        const labels = []
        for ( let mat of this.labelMat ) {
            const myText = new Text()
            this.cont3D.add(myText)
    
         //   myText.material = base 
            // Set properties to configure:
            myText.text = 'Hello world!'
            myText.fontSize = 0.2
         
    
            // Update the rendering:
            myText.sync()
    
        //    const mesh = new THREE.Sprite( mat )
             let mesh = myText
            labels.push( mesh )
            mesh.scale.setScalar(0.05)

            const connector = new THREE.Sprite( this.connMat.clone() )
            mesh.connector = connector
            mesh.add( connector )
            connector.scale.setScalar( 0.05)
           
        }
        this.labels = labels 

        let i = 0 
        for ( let key of ['Mars', 'Neptune', 'Mercury', 'Moon', 'Venus']) {
            const obj = this.planets.getObjectByName( 'SM_' + key )
            obj.add( labels[ i ])
            labels[i].scale.setScalar( 1 / obj.scale.x )
            const { connector } = labels[ i ]

            if ( i % 2 ){
                connector.rotation.z = Math.PI 
                connector.position.y = 0.1 
                labels[ i ].position.y = - 0.05
                labels[  i ].side = -1
            } else {
                
                connector.position.y = - 0.1 
                labels[ i ].position.y =  0.05
                labels[  i ].side = 1
            }
            labels[ i ].scale.setScalar( 0.1)
            
            i ++ 
        }

        for ( ; i < 8 ; i ++){
            const obj = this.planets.getObjectByName( 'SM_Saturn_Rings'  )
            obj.add( labels[ i ])
            const label = labels[ i ]
            labels[i].scale.setScalar( 1 / obj.scale.x )
            const { connector } = labels[ i ]

            label.position.x = Math.sin( (i -5) / 3 * Math.PI * 2 ) *  this.labelRad
            label.position.z = Math.cos( (i -5) / 3 * Math.PI * 2 ) *  this.labelRad
 
            if ( i % 2 ){
                connector.rotation.z = Math.PI 
                connector.position.y = 0.1 
                labels[ i ].position.y = - 0.05
                labels[  i ].side = -1
            } else {
                
                connector.position.y = - 0.1 
                labels[ i ].position.y =  0.05
                labels[  i ].side = 1
            }
            labels[ i ].scale.setScalar( 0.1)
        }
    }




    //called every frame, dt: time passed between frames
    update(dt) {

        super.update(dt)

        this.easedScroll = damp( this.easedScroll, this.currentScroll, 5, dt )
        // console.log(this.easedScroll, this.currentScroll)

     
        this.labels.forEach( (l,i) => {
            if ( !l.parent ) return 
            l.scale.setScalar( 0.25 * 1 / l.parent.scale.x ) 
            l.position.y = 0.2 *  l.side * 1 / l.parent.scale.x 
           
            if ( i < 5 ) return 
            l.quaternion.copy( this.me.camera.quaternion )
            l.position.x = Math.sin( (i -5) / 3 * Math.PI * 2 ) *  this.labelRad
            l.position.z = Math.cos( (i -5) / 3 * Math.PI * 2 ) *  this.labelRad
        })
            this.planetMixer.update(dt * 10  )

            
            //this.clipTime += dt

  

    }

    dispose() {

        this.cont3D.remove(this.mesh)

    }
}


// DO NOT DELETE THIS SHIT

typeManager.registerClass('Comp.ServicenowPlanets', ServiceNowPlanets);

export default ServiceNowPlanets;

