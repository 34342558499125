import { cloud, three } from "xx-packages"
import * as THREE from 'three'

const { extAssetCache } = three
const { cloudClient } = cloud

export default async function generatePlanets() {


    const doc = cloudClient.getDoc(this.inputs.get("models_planets"))
    const model = await extAssetCache.load(doc)
    console.log("Planets", model)
    this.planets = model 
    this.cont3D.add(model)
    this.planetsCont = model

    this.planetsCont = new THREE.Group()
    this.planetsCont.add( model )
    this.cont3D.add( this.planetsCont )
    model.scale.setScalar(10 )

    this.planetsCont.rotation.x = 0.3

 


   // model.position.x = 50
 

    const numPlanets = model.children.length 
    const dim = 10 
    for ( let i in model.children ){
        const planet = model.children[ i ]
        //planet.position.x = - numPlanets / 2 * dim + i * dim 
    }

    //model.position.z = -30 

    const encoding = THREE.sRGBEncoding


    const planets = [
        'Earth',
        'Moon',

        'Mercury',
        'Venus',
        'Mars',
        'Jupiter',
        'Saturn',
        'Neptune'
    ]

    this.planetMixer = new THREE.AnimationMixer(model);
    const clips = model.userData.model.animations;

    this.planetClipIndex = 0

    this.planetClip = clips[this.planetClipIndex]



    this.planetAction = this.planetMixer.clipAction(this.planetClip);
    this.planetAction.clampWhenFinished = true 
    this.planetAction.loop = THREE.LoopOnce
    this.planetAction.play();


    this.planetMixer.addEventListener('finished', e =>{
        // console.warn('PLANETS FINISHED')
        if ( e.direction === 1 )this.startNext('mixer')
        else this.startPrevious('mixer')
    })

    const speeds = {
        'SM_Neptune': 0.15,
        'SM_Mars': 0.165,
        'SM_Mercury':0.3,
        'SM_Venus': 0.25
    }

    const v1 = new THREE.Vector3()
    const v2 = new THREE.Vector3()
    const firstQuat = new THREE.Quaternion()
    const lastQuat = new THREE.Quaternion()
    this.planetContainers = this.planets.children.filter(planet => planet.name !== "Dummy003" && planet.name !== 'Sun' && planet.name !== 'SM_Moon'  ).map( planet =>{
        const cont = new THREE.Group()
        this.planets.add( cont )
        cont.add( planet )
        cont.rotSpeed = speeds[ planet.name]
        cont.name = planet.name + '_cont'


        // cont.add( new THREE.Mesh(
        //     new THREE.BoxBufferGeometry( 0.1, 1, 0.1 ),
        //     new THREE.MeshBasicMaterial({ wireframe: true })
        // ))

        // const track = this.planetAction._clip.tracks.find( t => t.name.match( planet.name ) && t.name.match( 'quaternion' ) )
        // cont.track = track


        // v1.set(0,1,0)
        // v2.copy( v1 )

        // firstQuat.set( 
        //     track.values[ i * 4 + 0 ],
        //     track.values[ i * 4 + 1 ],
        //     track.values[ i * 4 + 2 ],
        //     track.values[ i * 4 + 3 ],
        // )
        // lastQuat.set( 
        //     track.values[ i * 4 - 16 ],
        //     track.values[ i * 4 - 16 ],
        //     track.values[ i * 4 - 16  ],
        //     track.values[ i * 4 - 16  ],
        // )

        // v1.applyQuaternion( firstQuat )
        // v2.applyQuaternion( lastQuat )
        // v1.normalize()
        // v2.normalize()

        // const contUp = new THREE.Quaternion()
        // contUp.setFromAxisAngle( v1, v2 )
        // cont.contUp = contUp
        // console.log( contUp )
        return cont 
    })



    const radius = 0.8
    for ( let track of this.planetAction._clip.tracks ) {
        if ( !track.name.match('position')) continue;
        const r = radius 
        for ( let i = 0; i < Math.floor(track.values.length/ 3 ) ; i ++ ){
            
            if ( track.name.match(/mars/i)  || track.name.match(/moon/i) ) {

                continue
            }
            continue 
            track.values[ i * 3 + 0 ] *= r 
            track.values[ i * 3 + 2 ] *= r 

        }
    }

   // this.planets = {}

   //for ( let key of planets ) this.planets[ key ] = model.getObjectByName( key )
    // for ( let planet of Object.values( this.planets ) ) planet.visible = false 

    if (this.renderer.scene.background && this.renderer.scene.background.uuid) {
        model.traverse(m => {
            if (!m.material) return
            m.material.envMap = this.renderer.scene.background


            const { material } = m

            if (material.map) material.map.encoding = encoding;
            if (material.emissiveMap) material.emissiveMap.encoding = encoding;
            if (material.map || material.emissiveMap) material.needsUpdate = true;
        })
    }
}


