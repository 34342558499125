import * as THREE from 'three'
import { cloud , three, input  } from "xx-packages"
const { cloudClient } = cloud 
const { VisComp, materialManager, ShaderUtils2 , ShaderChunks} = three
const { assetToTexture } = materialManager 
export default function generatePoints() {

    const blendingModes = [THREE.NoBlending,THREE.NormalBlending,THREE.AdditiveBlending,THREE.SubtractiveBlending,THREE.MultiplyBlending,THREE.CustomBlending];

    const geom = new THREE.BufferGeometry()
    const count = 10000
    const pos = new Float32Array( count * 3  )
    for ( let i = 0; i < count ; i ++ ){
        pos[ i * 3 + 0 ] = THREE.MathUtils.randFloat( - 100, 100 )
        pos[ i * 3 + 1 ] = THREE.MathUtils.randFloat( - 100, 100 )
        pos[ i * 3 + 2 ] = THREE.MathUtils.randFloat( - 100, -20 )
    }
    geom.setAttribute( 'position', new THREE.BufferAttribute( pos, 3  ) )

    const size = new Float32Array( count  )
    for ( let i = 0; i < count ; i ++ ){
        size[ i  ] = THREE.MathUtils.randFloat( 0.1, 1 )
 
    }
    geom.setAttribute( 'pointSize', new THREE.BufferAttribute( size, 1 ) )


    const pointMaterialOptions = this.inputs.get('particles_options')
    // convert asset properties to values usable by PointsMaterial
    if (pointMaterialOptions.color) pointMaterialOptions.color = new THREE.Color(pointMaterialOptions.color)
    if (pointMaterialOptions.blendingMode) pointMaterialOptions.blendingMode = blendingModes[pointMaterialOptions.blendingMode]
    if (pointMaterialOptions.alphaMap) pointMaterialOptions.alphaMap = assetToTexture(pointMaterialOptions.alphaMap)
    if (pointMaterialOptions.map) pointMaterialOptions.map = assetToTexture(pointMaterialOptions.map)


    // create vertex color buffer if necessary
    // this will override the single color
    if (pointMaterialOptions.vertexColors) {

        const colors = []
        const asset = cloudClient.getDoc(pointMaterialOptions.vertexColors).d
        if (asset.elems) {
            // console.log("elems", asset.elems)
            for (let el of asset.elems) {
                colors.push(new THREE.Color(el))
            }
            let col
            const colorsBuffer = new Float32Array(count * 3)
            for (let i = 0; i < count; i++) {
                col = colors[i % colors.length]
                colorsBuffer[i * 3 + 0] = col.r
                colorsBuffer[i * 3 + 1] = col.g
                colorsBuffer[i * 3 + 2] = col.b
            }
            geom.setAttribute('color', new THREE.BufferAttribute(colorsBuffer, 3))
            pointMaterialOptions.vertexColors = true

        }

    }

    const chunk = ShaderChunks.pointSize

    this.mat =  ShaderUtils2.buildMaterial( {
        baseMaterial: new THREE.PointsMaterial( pointMaterialOptions ),
        vertex: chunk.vertex,
        fragment: chunk.fragment,
        attributes: chunk.attributes,
        varyings: chunk.varyings ,
        uniforms: chunk.uniforms
    })
    const points = new THREE.Points(geom, this.mat)
    this.cont3D.add(points)
    this.particles = points
}