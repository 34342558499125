const resizeBody = _ => {
    requestAnimationFrame(_ => {
        let height = window.innerHeight
        document.body.style.width = window.innerWidth + "px"
        document.body.style.height = height + "px"

        document.documentElement.style.setProperty('--vh', window.innerHeight / 100 + 'px' );
    })

}

const onOrientationChange = (m, onOrientationChangeCallback ) => {
    setTimeout(_ => {
        if (m.matches || window.orientation === undefined || window.orientation === 0 || window.innerHeight > 700 || window.screen.width > 1000) {
            // Changed to portrait
            
            document.body.classList.remove('landscape')
            
            setTimeout(_ => {

                resizeBody()
                if ( typeof onOrientationChangeCallback === 'function' ) onOrientationChangeCallback( false )
                // a bit hackish, it's the only way I've found of making sure there's no white space
                // at the bottom of the screen when coming back from landscape on iOS
                setTimeout(_ => {
                    document.querySelector("html").style.height = "100vh"
                    setTimeout(_ => {
                        document.querySelector("html").style.height = "100%"
                    }, 200)
                }, 200)
            }, 500)
        }
        else {
            document.body.classList.add('landscape')
            
            // Changed to landscape
            setTimeout(_ => {
                if ( typeof onOrientationChangeCallback === 'function' ) onOrientationChangeCallback( true )
                resizeBody()
            }, 500)
        }
    }, 500)


}




/**
 * sets the body dimensions to equal window dimensions
 * makes sure going back and forth from landscape doesn't cause issues
 * sets a CSS variable --vh to equal one percent of viewport height 
 * see https://css-tricks.com/the-trick-to-viewport-units-on-mobile/
 */
export default function fullScreenBody( onOrientationChangeCallback ) {
    window.addEventListener("resize", resizeBody)
    // Find matches
    var mql = window.matchMedia("(orientation: portrait)");
    // If there are matches, we're in portrait
    if (mql.matches || window.orientation === undefined || window.orientation === 0 || window.innerHeight > 700 || window.screen.width > 1000) {
        // Portrait orientation
        onOrientationChangeCallback( false )
        document.body.classList.remove('landscape')
    } else {
        // Landscape orientation
        onOrientationChangeCallback( true )
        document.body.classList.add('landscape')
    }
    // Add a media query change listener
    if (mql.addEventListener && typeof mql.addEventListener === "function") mql.addEventListener("change",m => onOrientationChange(m, onOrientationChangeCallback));
    else if (mql.addListener && typeof mql.addListener === "function") mql.addListener(m =>onOrientationChange(m, onOrientationChangeCallback))
    resizeBody()
}