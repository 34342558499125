
import * as THREE from 'three'
import {  three   } from "xx-packages"
const {  materialManager } = three
export function initSupernova() {


    const { geometry, material } = this.children[1].particles
    this.particles = this.children[1].particles
    this.children[1].particles.morphTargetInfluences = [0, 0, 0 ]
    geometry.morphAttributes.position = [];

    const { position } = geometry.attributes

    const pos2 = []
    const pos3 = []

    const pos4 = []

    const v = new THREE.Vector3()

    for (let i = 0; i < position.count; i++) {
        v.set(
            position.array[i * 3 + 0],
            position.array[i * 3 + 1],
            position.array[i * 3 + 2],
        )
        v.x *= Math.random() * 0.5
        v.y *= Math.random()
        v.z *= Math.random() * 0.5
        v.multiplyScalar(0.5)

        pos2.push(v.x, v.y, v.z)

        // half the particles explode in an outwards circle, rest in a tight cone perpendicular to the ecliptic plane

        // circle 
        if (i % 2) {
            const scale = Math.random() * 10
            v.x *= scale
            v.z *= scale
            v.y *= scale / 10

        } else {
            v.x *= (Math.random() - 0.5) * 3
            v.y *= (Math.random() - 0.5) * 20
            v.z *= (Math.random() - 0.5) * 3

            const scale = Math.random() * 30
            v.x *= scale * 0.1
            v.z *= scale * 0.1
            v.y *= scale
        }



        pos3.push(v.x, v.y, v.z)

       let r = Math.random()
       let sign = Math.random()< 0.5? 1 : -1 
       if ( r < 0.3 ){
            pos4.push(
                THREE.Math.randFloat( 6, 30 ) * sign ,
                THREE.Math.randFloat( -30, 30 ),
                THREE.Math.randFloat( -30, 30 )
            )
       } else if ( r < 0.6 ) {
        pos4.push(
            
            THREE.Math.randFloat( -30, 30 ),
            THREE.Math.randFloat( 6, 30 ) * sign ,
            THREE.Math.randFloat( -30, 30 )
        )
       } else {
        pos4.push(
            
            THREE.Math.randFloat( -30, 30 ),
            THREE.Math.randFloat( -30, 30 ),
            THREE.Math.randFloat( 6, 30 ) * sign ,
        )
       }

    }
    geometry.morphAttributes.position[0] = new THREE.Float32BufferAttribute(pos2, 3);
    geometry.morphAttributes.position[1] = new THREE.Float32BufferAttribute(pos3, 3);
    geometry.morphAttributes.position[2] = new THREE.Float32BufferAttribute(pos4, 3);

    material.morphTargets = true

    const base = new THREE.SpriteMaterial({
        transparent: true,
    
        depthTest: false,
        depthWrite: false ,
        transparent: true 
     //   sizeAttenuation: false
    })


    this.explosionMat = 				materialManager.build({ 
        base: base, 
        def:base, 
        asset: this.inputs.get('textures_explosion')
    })
    this.sprite = new THREE.Sprite( this.explosionMat )
    this.particles.add( this.sprite )
    this.sprite.scale.set( 1.7777, 1, 1)
    this.sprite.scale.multiplyScalar( 30 )
    this.explosionMat.opacity = 0 



    this.time = 0


}

export function startSupernova( dir = 1 ) {
    // console.log('start nova')
    // const influences = {
    //     start: 0,
    //     '0': 0,
    //     '1': 0,
    //     a: 0
    // }

    // this.influences = influences
    // const tl = gsap.timeline({})
    // tl.to(influences, { start: 1, duration: 4 })
    // tl.to(influences, { '0': 1, duration: 1 });
    // tl.to(influences, { '1': 1, '0': 0, duration: 0.1, delay: 0.3 });
    // tl.to(influences, { a: 1, duration: 1 })

    // tl.play()
    // this.supernovaTimeline = tl



    this.particles.rotation.y = 0
    this.particles.scale.setScalar(1 )
    this.particles.morphTargetInfluences[0] = 0
    this.particles.morphTargetInfluences[1] =  0

    if ( dir === 1){
        for ( let label of this.labels2 ){
            this.particles.attach( label )
        }
    }

    let supernovaTimeline = {
        steps: [

            {
                duration: 4,
                onUpdate: (prog,dt) => {
                    this.particles.rotation.y -= dt * prog 
                    this.particles.scale.setScalar(1 - prog * 0.2)
                    this.particles.morphTargetInfluences[0] = 0
                    this.particles.morphTargetInfluences[1] =  0
                }
            },
            {
                duration: 1,
                onUpdate: (prog,dt) => {
                    this.particles.rotation.y -= dt * (1 + prog * 3 )
                    this.particles.morphTargetInfluences[0] =  prog
                    
                    this.particles.morphTargetInfluences[1] =  0
                    
                }
            },
            {
                duration: 0.06,
                onUpdate: prog => {
                   this.particles.morphTargetInfluences[0] = 1 - prog
                    this.particles.morphTargetInfluences[1] =  prog
                    this.renderer.renderer.toneMappingExposure = 1 + prog * 9
                    this.explosionMat.opacity = prog 
                    // console.log('expl')

                    this.planets.visible = true 
                    this.planets.scale.setScalar( prog * 5 + 0.000001 )
                    this.planetAction.reset()
                    this.planetMixer.setTime( this.planetStartTime )
                }
            },
            {
                duration: 0.06,
                onUpdate: prog => {
                    this.particles.morphTargetInfluences[2] =  prog
                    this.renderer.renderer.toneMappingExposure = 1 // 10 - prog * 9 
                    this.explosionMat.opacity = 1 - prog 
                    // console.log('after expl')

                    this.planets.visible = true 
                    this.planets.scale.setScalar( prog * 5 + 5 )
                    this.planetAction.reset()
                    this.planetMixer.setTime( this.planetStartTime )
                },

            }
        ],
        perc: 0
    }



    // if ( dir === -1 ){
    //     supernovaTimeline = {
    //         steps: [
    //             {
    //                 duration: 1,
    //                 onUpdate: prog =>{
    //                     this.particles.morphTargetInfluences[0] = 0 
    //                     this.particles.morphTargetInfluences[1 ] = 0 
    //                     this.particles.morphTargetInfluences[2]  = 1 - prog 
    //                 }
    //             }
    //         ]
    //     }
    // }
    if ( dir === 1 ){
        supernovaTimeline.steps.unshift(
            {
                duration: 13,
                onUpdate: _=> null 
            }
        )
        supernovaTimeline.onEnd =_=>{
            this.particles.morphTargetInfluences[2] =  1
            this.renderer.renderer.toneMappingExposure = 1 
            this.explosionMat.opacity = 0
            this.renderer.renderer.toneMappingExposure = 1 
            for ( let label of this.labels2 ){
                this.cont3D.add( label )
                label.position.copy( label.worldPos )
            }
        }
    }
    if ( dir === -1 ){
        supernovaTimeline.onEnd =_=>{
            this.particles.morphTargetInfluences[0] =  0
            this.particles.morphTargetInfluences[1] =  0
            this.particles.morphTargetInfluences[2] =  0
            this.renderer.renderer.toneMappingExposure = 1 
            this.explosionMat.opacity = 0
            this.renderer.renderer.toneMappingExposure = 1 
            for ( let label of this.labels2 ){
                this.cont3D.add( label )
                label.position.copy( label.worldPos )
            }
        }
    }
    initTimeline(supernovaTimeline)

    this.supernovaTimeline = supernovaTimeline 
    this.timeline[ this.timelineInd ].duration = this.supernovaTimeline.duration
}

function initTimeline(timeline) {
    const duration = timeline.steps.reduce((prev, current) => prev + current.duration, 0)
    timeline.duration = duration
    let startTime = 0
    for (let step of timeline.steps) {
        step.startTime = startTime
        startTime += step.duration
        step.endTime = startTime
    }
   
    // console.warn('SUPERNOVA DURATION', duration, timeline)
}
function updateTimeline(timeline, dt) {
    timeline.perc += dt / timeline.duration
    if (timeline.perc < 0) timeline.perc = 0
    if (timeline.perc > 1) timeline.perc = 1
    const { perc } = timeline

    const elapsedTime = perc * timeline.duration

    for (let step of timeline.steps) {
        // not started yet 
        if (step.startTime >= elapsedTime) {
           // step.onUpdate(0)
        } else if (step.endTime <= elapsedTime) {
           // step.onUpdate(1)
           
        } else {
            const p = (elapsedTime - step.startTime ) / step.duration
            step.onUpdate( p, dt  )
        }
    }

    if (perc === 1) {

    }

}

export function resetSupernova( end = false ) {
    if ( end ){
      
        this.particles.scale.setScalar(0.8 )
        this.particles.morphTargetInfluences[0] = 0
        this.particles.morphTargetInfluences[1] =  1
        this.particles.morphTargetInfluences[2] =  1
        this.explosionMat.opacity = 0
        this.renderer.renderer.toneMappingExposure = 1 
        return 
    }
    this.particles.rotation.y = 0
    this.particles.scale.setScalar(1 )
    this.particles.morphTargetInfluences[0] = 0
    this.particles.morphTargetInfluences[1] =  0
    this.particles.morphTargetInfluences[2] =  0
    this.explosionMat.opacity = 0
    this.renderer.renderer.toneMappingExposure = 1 
}
export function updateSupernova(dt) {
    updateTimeline( this.supernovaTimeline , dt * this.timeline[ this.timelineInd ].direction )
}